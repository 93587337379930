.disabled_dropdown_item {
    cursor: not-allowed !important;
    opacity: 0.5 !important;
}

.scale_105 {
    transition: 0.3s ease-in;
}

.scale_105:hover {
    transform: scale(1.05);
}

.scale_110 {
    transition: 0.3s ease-in;
}

.scale_110:hover {
    transform: scale(1.1);
}

.invisiblescroll::-webkit-scrollbar {
    height: 0px
}