.recent-project-card-footer{
    background-color: var(--inf-theme-gray-600);
}

.recent-project-card-footer .recent-project-card-footer-project-name{
    color: white;
}

.recent-project-card-footer .recent-project-card-footer-secondary-info{
    color: var(--inf-theme-gray-400)
}

.filler_empty_state {
    min-height: 32px;
    background: #F5F6F8;
    border: 1px dashed #C5C7CF;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    color: #A7A8B2;
}

.visible_on_hover:hover .invisible {
    visibility: visible !important;
}

.invisible:focus {
    visibility: visible !important;
}