:root {
    /* Element Specific Definitions */
    /* Space Definitions */
    --inf-flex-grid-gap: 1rem;

    /* Color Definitions */
    --layer-main-bg: #FFFFFF;
    --layer-secondary-bg: #F5F6F8;
    --layer-main-text-color: #676878;
    --inf-warning-text-color: #FF5247;
    
    --dark-border-color: var(--inf-theme-gray-400);
    --light-border-color: var(--inf-theme-gray-300);
    --very-light-border-color: var(--inf-theme-gray-200);

    /* Theme Defininitions */
    --inf-theme-gray-100: #FFFFFF;
    --inf-theme-gray-200: #F5F6F8;
    --inf-theme-gray-300: #E6E9EE;
    --inf-theme-gray-400: #C5C7CF;
    --inf-theme-gray-500: #A7A8B3;
    --inf-theme-gray-600: #676878;
    --inf-theme-gray-700: #323338;
    --inf-theme-gray-800: #202126;
    --inf-theme-gray-900: #A7A8B2;

    /* Primary Highlight Colors */
    --inf-primary-blue: #0078ff;
    --inf-primary-blue-hover-color: #E5F1FF;

    /* Screen Breakpoint Definitions */
    --inf-breakpoint-sm: 640px;
    --inf-breakpoint-md: 768px;
    --inf-breakpoint-lg: 1024px;
    --inf-breakpoint-xl: 1280px;
    --inf-breakpoint-2xl: 1536px;

    /* Spacing Definitions */
    --spacing-0: 0px;
    --spacing-0p5: 0.125rem;
    --spacing-1: 0.25rem;
    --spacing-1p5: 0.375rem;
    --spacing-2: 0.5rem;
    --spacing-2p5: 0.625rem;
    --spacing-3: 0.75rem;
    --spacing-3p5: 0.875rem;
    --spacing-4: 1rem;
    --spacing-5: 1.25rem;
    --spacing-6: 1.5rem;
    --spacing-7: 1.75rem;
    --spacing-8: 2rem;
    --spacing-9: 2.25rem;
    --spacing-10: 2.5rem;
    --spacing-11: 2.75rem;
    --spacing-12: 3rem;
    --spacing-14: 3.5rem;
    --spacing-16: 4rem;
    --spacing-20: 5rem;
    --spacing-24: 6rem;
    --spacing-28: 7rem;
    --spacing-32: 8rem;
    --spacing-36: 9rem;
    --spacing-40: 10rem;
    --spacing-44: 11rem;
    --spacing-48: 12rem;
    --spacing-52: 13rem;
    --spacing-56: 14rem;
    --spacing-60: 15rem;
    --spacing-64: 16rem;
    --spacing-72: 18rem;
    --spacing-80: 20rem;
    --spacing-96: 24rem,
}

/* Margin classes based on spacing variables */
.inf-m-0 {
    margin: var(--spacing-0);
}

.inf-m-0p5 {
    margin: var(--spacing-0p5);
}

.inf-m-1 {
    margin: var(--spacing-1);
}

.inf-m-1p5 {
    margin: var(--spacing-1p5);
}

.inf-m-2 {
    margin: var(--spacing-2);
}

.inf-m-2p5 {
    margin: var(--spacing-2p5);
}

.inf-m-3 {
    margin: var(--spacing-3);
}

.inf-m-3p5 {
    margin: var(--spacing-3p5);
}

.inf-m-4 {
    margin: var(--spacing-4);
}

.inf-m-5 {
    margin: var(--spacing-5);
}

.inf-m-6 {
    margin: var(--spacing-6);
}

.inf-m-7 {
    margin: var(--spacing-7);
}

.inf-m-8 {
    margin: var(--spacing-8);
}

.inf-m-9 {
    margin: var(--spacing-9);
}

.inf-m-10 {
    margin: var(--spacing-10);
}

.inf-m-11 {
    margin: var(--spacing-11);
}

.inf-m-12 {
    margin: var(--spacing-12);
}

.inf-m-14 {
    margin: var(--spacing-14);
}

.inf-m-16 {
    margin: var(--spacing-16);
}

.inf-m-20 {
    margin: var(--spacing-20);
}

.inf-m-24 {
    margin: var(--spacing-24);
}

.inf-m-28 {
    margin: var(--spacing-28);
}

.inf-m-32 {
    margin: var(--spacing-32);
}

.inf-m-36 {
    margin: var(--spacing-36);
}

.inf-m-40 {
    margin: var(--spacing-40);
}

.inf-m-44 {
    margin: var(--spacing-44);
}

.inf-m-48 {
    margin: var(--spacing-48);
}

.inf-m-52 {
    margin: var(--spacing-52);
}

.inf-m-56 {
    margin: var(--spacing-56);
}

.inf-m-60 {
    margin: var(--spacing-60);
}

.inf-m-64 {
    margin: var(--spacing-64);
}

.inf-m-72 {
    margin: var(--spacing-72);
}

.inf-m-80 {
    margin: var(--spacing-80);
}

.inf-m-96 {
    margin: var(--spacing-96);
}


/* Margin-left classes based on spacing variables */
.inf-ml-0 {
    margin-left: var(--spacing-0);
}

.inf-ml-0p5 {
    margin-left: var(--spacing-0p5);
}

.inf-ml-1 {
    margin-left: var(--spacing-1);
}

.inf-ml-1p5 {
    margin-left: var(--spacing-1p5);
}

.inf-ml-2 {
    margin-left: var(--spacing-2);
}

.inf-ml-2p5 {
    margin-left: var(--spacing-2p5);
}

.inf-ml-3 {
    margin-left: var(--spacing-3);
}

.inf-ml-3p5 {
    margin-left: var(--spacing-3p5);
}

.inf-ml-4 {
    margin-left: var(--spacing-4);
}

.inf-ml-5 {
    margin-left: var(--spacing-5);
}

.inf-ml-6 {
    margin-left: var(--spacing-6);
}

.inf-ml-7 {
    margin-left: var(--spacing-7);
}

.inf-ml-8 {
    margin-left: var(--spacing-8);
}

.inf-ml-9 {
    margin-left: var(--spacing-9);
}

.inf-ml-10 {
    margin-left: var(--spacing-10);
}

.inf-ml-11 {
    margin-left: var(--spacing-11);
}

.inf-ml-12 {
    margin-left: var(--spacing-12);
}

.inf-ml-14 {
    margin-left: var(--spacing-14);
}

.inf-ml-16 {
    margin-left: var(--spacing-16);
}

.inf-ml-20 {
    margin-left: var(--spacing-20);
}

.inf-ml-24 {
    margin-left: var(--spacing-24);
}

.inf-ml-28 {
    margin-left: var(--spacing-28);
}

.inf-ml-32 {
    margin-left: var(--spacing-32);
}

.inf-ml-36 {
    margin-left: var(--spacing-36);
}

.inf-ml-40 {
    margin-left: var(--spacing-40);
}

.inf-ml-44 {
    margin-left: var(--spacing-44);
}

.inf-ml-48 {
    margin-left: var(--spacing-48);
}

.inf-ml-52 {
    margin-left: var(--spacing-52);
}

.inf-ml-56 {
    margin-left: var(--spacing-56);
}

.inf-ml-60 {
    margin-left: var(--spacing-60);
}

.inf-ml-64 {
    margin-left: var(--spacing-64);
}

.inf-ml-72 {
    margin-left: var(--spacing-72);
}

.inf-ml-80 {
    margin-left: var(--spacing-80);
}

.inf-ml-96 {
    margin-left: var(--spacing-96);
}

.inf-ml-auto {
    margin-left: auto;
}

/* Margin-right classes based on spacing variables */
.inf-mr-0 {
    margin-right: var(--spacing-0);
}

.inf-mr-0p5 {
    margin-right: var(--spacing-0p5);
}

.inf-mr-1 {
    margin-right: var(--spacing-1);
}

.inf-mr-1p5 {
    margin-right: var(--spacing-1p5);
}

.inf-mr-2 {
    margin-right: var(--spacing-2);
}

.inf-mr-2p5 {
    margin-right: var(--spacing-2p5);
}

.inf-mr-3 {
    margin-right: var(--spacing-3);
}

.inf-mr-3p5 {
    margin-right: var(--spacing-3p5);
}

.inf-mr-4 {
    margin-right: var(--spacing-4);
}

.inf-mr-5 {
    margin-right: var(--spacing-5);
}

.inf-mr-6 {
    margin-right: var(--spacing-6);
}

.inf-mr-7 {
    margin-right: var(--spacing-7);
}

.inf-mr-8 {
    margin-right: var(--spacing-8);
}

.inf-mr-9 {
    margin-right: var(--spacing-9);
}

.inf-mr-10 {
    margin-right: var(--spacing-10);
}

.inf-mr-11 {
    margin-right: var(--spacing-11);
}

.inf-mr-12 {
    margin-right: var(--spacing-12);
}

.inf-mr-14 {
    margin-right: var(--spacing-14);
}

.inf-mr-16 {
    margin-right: var(--spacing-16);
}

.inf-mr-20 {
    margin-right: var(--spacing-20);
}

.inf-mr-24 {
    margin-right: var(--spacing-24);
}

.inf-mr-28 {
    margin-right: var(--spacing-28);
}

.inf-mr-32 {
    margin-right: var(--spacing-32);
}

.inf-mr-36 {
    margin-right: var(--spacing-36);
}

.inf-mr-40 {
    margin-right: var(--spacing-40);
}

.inf-mr-44 {
    margin-right: var(--spacing-44);
}

.inf-mr-48 {
    margin-right: var(--spacing-48);
}

.inf-mr-52 {
    margin-right: var(--spacing-52);
}

.inf-mr-56 {
    margin-right: var(--spacing-56);
}

.inf-mr-60 {
    margin-right: var(--spacing-60);
}

.inf-mr-64 {
    margin-right: var(--spacing-64);
}

.inf-mr-72 {
    margin-right: var(--spacing-72);
}

.inf-mr-80 {
    margin-right: var(--spacing-80);
}

.inf-mr-96 {
    margin-right: var(--spacing-96);
}

.inf-mr-auto {
    margin-right: auto;
}

/* Margin-top classes based on spacing variables */
.inf-mt-0 {
    margin-top: var(--spacing-0);
}

.inf-mt-0p5 {
    margin-top: var(--spacing-0p5);
}

.inf-mt-1 {
    margin-top: var(--spacing-1);
}

.inf-mt-1p5 {
    margin-top: var(--spacing-1p5);
}

.inf-mt-2 {
    margin-top: var(--spacing-2);
}

.inf-mt-2p5 {
    margin-top: var(--spacing-2p5);
}

.inf-mt-3 {
    margin-top: var(--spacing-3);
}

.inf-mt-3p5 {
    margin-top: var(--spacing-3p5);
}

.inf-mt-4 {
    margin-top: var(--spacing-4);
}

.inf-mt-5 {
    margin-top: var(--spacing-5);
}

.inf-mt-6 {
    margin-top: var(--spacing-6);
}

.inf-mt-7 {
    margin-top: var(--spacing-7);
}

.inf-mt-8 {
    margin-top: var(--spacing-8);
}

.inf-mt-9 {
    margin-top: var(--spacing-9);
}

.inf-mt-10 {
    margin-top: var(--spacing-10);
}

.inf-mt-11 {
    margin-top: var(--spacing-11);
}

.inf-mt-12 {
    margin-top: var(--spacing-12);
}

.inf-mt-14 {
    margin-top: var(--spacing-14);
}

.inf-mt-16 {
    margin-top: var(--spacing-16);
}

.inf-mt-20 {
    margin-top: var(--spacing-20);
}

.inf-mt-24 {
    margin-top: var(--spacing-24);
}

.inf-mt-28 {
    margin-top: var(--spacing-28);
}

.inf-mt-32 {
    margin-top: var(--spacing-32);
}

.inf-mt-36 {
    margin-top: var(--spacing-36);
}

.inf-mt-40 {
    margin-top: var(--spacing-40);
}

.inf-mt-44 {
    margin-top: var(--spacing-44);
}

.inf-mt-48 {
    margin-top: var(--spacing-48);
}

.inf-mt-52 {
    margin-top: var(--spacing-52);
}

.inf-mt-56 {
    margin-top: var(--spacing-56);
}

.inf-mt-60 {
    margin-top: var(--spacing-60);
}

.inf-mt-64 {
    margin-top: var(--spacing-64);
}

.inf-mt-72 {
    margin-top: var(--spacing-72);
}

.inf-mt-80 {
    margin-top: var(--spacing-80);
}

.inf-mt-96 {
    margin-top: var(--spacing-96);
}

/* Margin-bottom classes based on spacing variables */
.inf-mb-0 {
    margin-bottom: var(--spacing-0);
}

.inf-mb-0p5 {
    margin-bottom: var(--spacing-0p5);
}

.inf-mb-1 {
    margin-bottom: var(--spacing-1);
}

.inf-mb-1p5 {
    margin-bottom: var(--spacing-1p5);
}

.inf-mb-2 {
    margin-bottom: var(--spacing-2);
}

.inf-mb-2p5 {
    margin-bottom: var(--spacing-2p5);
}

.inf-mb-3 {
    margin-bottom: var(--spacing-3);
}

.inf-mb-3p5 {
    margin-bottom: var(--spacing-3p5);
}

.inf-mb-4 {
    margin-bottom: var(--spacing-4);
}

.inf-mb-5 {
    margin-bottom: var(--spacing-5);
}

.inf-mb-6 {
    margin-bottom: var(--spacing-6);
}

.inf-mb-7 {
    margin-bottom: var(--spacing-7);
}

.inf-mb-8 {
    margin-bottom: var(--spacing-8);
}

.inf-mb-9 {
    margin-bottom: var(--spacing-9);
}

.inf-mb-10 {
    margin-bottom: var(--spacing-10);
}

.inf-mb-11 {
    margin-bottom: var(--spacing-11);
}

.inf-mb-12 {
    margin-bottom: var(--spacing-12);
}

.inf-mb-14 {
    margin-bottom: var(--spacing-14);
}

.inf-mb-16 {
    margin-bottom: var(--spacing-16);
}

.inf-mb-20 {
    margin-bottom: var(--spacing-20);
}

.inf-mb-24 {
    margin-bottom: var(--spacing-24);
}

.inf-mb-28 {
    margin-bottom: var(--spacing-28);
}

.inf-mb-32 {
    margin-bottom: var(--spacing-32);
}

.inf-mb-36 {
    margin-bottom: var(--spacing-36);
}

.inf-mb-40 {
    margin-bottom: var(--spacing-40);
}

.inf-mb-44 {
    margin-bottom: var(--spacing-44);
}

.inf-mb-48 {
    margin-bottom: var(--spacing-48);
}

.inf-mb-52 {
    margin-bottom: var(--spacing-52);
}

.inf-mb-56 {
    margin-bottom: var(--spacing-56);
}

.inf-mb-60 {
    margin-bottom: var(--spacing-60);
}

.inf-mb-64 {
    margin-bottom: var(--spacing-64);
}

.inf-mb-72 {
    margin-bottom: var(--spacing-72);
}

.inf-mb-80 {
    margin-bottom: var(--spacing-80);
}

.inf-mb-96 {
    margin-bottom: var(--spacing-96);
}

/* Horizontal Margin */
.inf-mx-0 {
    margin-left: var(--spacing-0);
    margin-right: var(--spacing-0);
}

.inf-mx-0p5 {
    margin-left: var(--spacing-0p5);
    margin-right: var(--spacing-0p5);
}

.inf-mx-1 {
    margin-left: var(--spacing-1);
    margin-right: var(--spacing-1);
}

.inf-mx-1p5 {
    margin-left: var(--spacing-1p5);
    margin-right: var(--spacing-1p5);
}

.inf-mx-2 {
    margin-left: var(--spacing-2);
    margin-right: var(--spacing-2);
}

.inf-mx-2p5 {
    margin-left: var(--spacing-2p5);
    margin-right: var(--spacing-2p5);
}

.inf-mx-3 {
    margin-left: var(--spacing-3);
    margin-right: var(--spacing-3);
}

.inf-mx-3p5 {
    margin-left: var(--spacing-3p5);
    margin-right: var(--spacing-3p5);
}

.inf-mx-4 {
    margin-left: var(--spacing-4);
    margin-right: var(--spacing-4);
}

.inf-mx-5 {
    margin-left: var(--spacing-5);
    margin-right: var(--spacing-5);
}

.inf-mx-6 {
    margin-left: var(--spacing-6);
    margin-right: var(--spacing-6);
}

.inf-mx-7 {
    margin-left: var(--spacing-7);
    margin-right: var(--spacing-7);
}

.inf-mx-8 {
    margin-left: var(--spacing-8);
    margin-right: var(--spacing-8);
}

.inf-mx-9 {
    margin-left: var(--spacing-9);
    margin-right: var(--spacing-9);
}

.inf-mx-10 {
    margin-left: var(--spacing-10);
    margin-right: var(--spacing-10);
}

.inf-mx-11 {
    margin-left: var(--spacing-11);
    margin-right: var(--spacing-11);
}

.inf-mx-12 {
    margin-left: var(--spacing-12);
    margin-right: var(--spacing-12);
}

.inf-mx-14 {
    margin-left: var(--spacing-14);
    margin-right: var(--spacing-14);
}

.inf-mx-16 {
    margin-left: var(--spacing-16);
    margin-right: var(--spacing-16);
}

.inf-mx-20 {
    margin-left: var(--spacing-20);
    margin-right: var(--spacing-20);
}

.inf-mx-24 {
    margin-left: var(--spacing-24);
    margin-right: var(--spacing-24);
}

.inf-mx-28 {
    margin-left: var(--spacing-28);
    margin-right: var(--spacing-28);
}

.inf-mx-32 {
    margin-left: var(--spacing-32);
    margin-right: var(--spacing-32);
}

.inf-mx-36 {
    margin-left: var(--spacing-36);
    margin-right: var(--spacing-36);
}

.inf-mx-40 {
    margin-left: var(--spacing-40);
    margin-right: var(--spacing-40);
}

.inf-mx-44 {
    margin-left: var(--spacing-44);
    margin-right: var(--spacing-44);
}

.inf-mx-48 {
    margin-left: var(--spacing-48);
    margin-right: var(--spacing-48);
}

.inf-mx-52 {
    margin-left: var(--spacing-52);
    margin-right: var(--spacing-52);
}

.inf-mx-56 {
    margin-left: var(--spacing-56);
    margin-right: var(--spacing-56);
}

.inf-mx-60 {
    margin-left: var(--spacing-60);
    margin-right: var(--spacing-60);
}

.inf-mx-64 {
    margin-left: var(--spacing-64);
    margin-right: var(--spacing-64);
}

.inf-mx-72 {
    margin-left: var(--spacing-72);
    margin-right: var(--spacing-72);
}

.inf-mx-80 {
    margin-left: var(--spacing-80);
    margin-right: var(--spacing-80);
}

.inf-mx-96 {
    margin-left: var(--spacing-96);
    margin-right: var(--spacing-96);
}

/* Vertical Margin */
.inf-my-0 {
    margin-top: var(--spacing-0);
    margin-bottom: var(--spacing-0);
}

.inf-my-0p5 {
    margin-top: var(--spacing-0p5);
    margin-bottom: var(--spacing-0p5);
}

.inf-my-1 {
    margin-top: var(--spacing-1);
    margin-bottom: var(--spacing-1);
}

.inf-my-1p5 {
    margin-top: var(--spacing-1p5);
    margin-bottom: var(--spacing-1p5);
}

.inf-my-2 {
    margin-top: var(--spacing-2);
    margin-bottom: var(--spacing-2);
}

.inf-my-2p5 {
    margin-top: var(--spacing-2p5);
    margin-bottom: var(--spacing-2p5);
}

.inf-my-3 {
    margin-top: var(--spacing-3);
    margin-bottom: var(--spacing-3);
}

.inf-my-3p5 {
    margin-top: var(--spacing-3p5);
    margin-bottom: var(--spacing-3p5);
}

.inf-my-4 {
    margin-top: var(--spacing-4);
    margin-bottom: var(--spacing-4);
}

.inf-my-5 {
    margin-top: var(--spacing-5);
    margin-bottom: var(--spacing-5);
}

.inf-my-6 {
    margin-top: var(--spacing-6);
    margin-bottom: var(--spacing-6);
}

.inf-my-7 {
    margin-top: var(--spacing-7);
    margin-bottom: var(--spacing-7);
}

.inf-my-8 {
    margin-top: var(--spacing-8);
    margin-bottom: var(--spacing-8);
}

.inf-my-9 {
    margin-top: var(--spacing-9);
    margin-bottom: var(--spacing-9);
}

.inf-my-10 {
    margin-top: var(--spacing-10);
    margin-bottom: var(--spacing-10);
}

.inf-my-11 {
    margin-top: var(--spacing-11);
    margin-bottom: var(--spacing-11);
}

.inf-my-12 {
    margin-top: var(--spacing-12);
    margin-bottom: var(--spacing-12);
}

.inf-my-14 {
    margin-top: var(--spacing-14);
    margin-bottom: var(--spacing-14);
}

.inf-my-16 {
    margin-top: var(--spacing-16);
    margin-bottom: var(--spacing-16);
}

.inf-my-20 {
    margin-top: var(--spacing-20);
    margin-bottom: var(--spacing-20);
}

.inf-my-24 {
    margin-top: var(--spacing-24);
    margin-bottom: var(--spacing-24);
}

.inf-my-28 {
    margin-top: var(--spacing-28);
    margin-bottom: var(--spacing-28);
}

.inf-my-32 {
    margin-top: var(--spacing-32);
    margin-bottom: var(--spacing-32);
}

.inf-my-36 {
    margin-top: var(--spacing-36);
    margin-bottom: var(--spacing-36);
}

.inf-my-40 {
    margin-top: var(--spacing-40);
    margin-bottom: var(--spacing-40);
}

.inf-my-44 {
    margin-top: var(--spacing-44);
    margin-bottom: var(--spacing-44);
}

.inf-my-48 {
    margin-top: var(--spacing-48);
    margin-bottom: var(--spacing-48);
}

.inf-my-52 {
    margin-top: var(--spacing-52);
    margin-bottom: var(--spacing-52);
}

.inf-my-56 {
    margin-top: var(--spacing-56);
    margin-bottom: var(--spacing-56);
}

.inf-my-60 {
    margin-top: var(--spacing-60);
    margin-bottom: var(--spacing-60);
}

.inf-my-64 {
    margin-top: var(--spacing-64);
    margin-bottom: var(--spacing-64);
}

.inf-my-72 {
    margin-top: var(--spacing-72);
    margin-bottom: var(--spacing-72);
}

.inf-my-80 {
    margin-top: var(--spacing-80);
    margin-bottom: var(--spacing-80);
}

.inf-my-96 {
    margin-top: var(--spacing-96);
    margin-bottom: var(--spacing-96);
}

/* Padding classes based on spacing variables */
.inf-p-0 {
    padding: var(--spacing-0);
}

.inf-p-0p5 {
    padding: var(--spacing-0p5);
}

.inf-p-1 {
    padding: var(--spacing-1);
}

.inf-p-1p5 {
    padding: var(--spacing-1p5);
}

.inf-p-2 {
    padding: var(--spacing-2);
}

.inf-p-2p5 {
    padding: var(--spacing-2p5);
}

.inf-p-3 {
    padding: var(--spacing-3);
}

.inf-p-3p5 {
    padding: var(--spacing-3p5);
}

.inf-p-4 {
    padding: var(--spacing-4);
}

.inf-p-5 {
    padding: var(--spacing-5);
}

.inf-p-6 {
    padding: var(--spacing-6);
}

.inf-p-7 {
    padding: var(--spacing-7);
}

.inf-p-8 {
    padding: var(--spacing-8);
}

.inf-p-9 {
    padding: var(--spacing-9);
}

.inf-p-10 {
    padding: var(--spacing-10);
}

.inf-p-11 {
    padding: var(--spacing-11);
}

.inf-p-12 {
    padding: var(--spacing-12);
}

.inf-p-14 {
    padding: var(--spacing-14);
}

.inf-p-16 {
    padding: var(--spacing-16);
}

.inf-p-20 {
    padding: var(--spacing-20);
}

.inf-p-24 {
    padding: var(--spacing-24);
}

.inf-p-28 {
    padding: var(--spacing-28);
}

.inf-p-32 {
    padding: var(--spacing-32);
}

.inf-p-36 {
    padding: var(--spacing-36);
}

.inf-p-40 {
    padding: var(--spacing-40);
}

.inf-p-44 {
    padding: var(--spacing-44);
}

.inf-p-48 {
    padding: var(--spacing-48);
}

.inf-p-52 {
    padding: var(--spacing-52);
}

.inf-p-56 {
    padding: var(--spacing-56);
}

.inf-p-60 {
    padding: var(--spacing-60);
}

.inf-p-64 {
    padding: var(--spacing-64);
}

.inf-p-72 {
    padding: var(--spacing-72);
}

.inf-p-80 {
    padding: var(--spacing-80);
}

.inf-p-96 {
    padding: var(--spacing-96);
}


/* Padding-left classes based on spacing variables */
.inf-pl-0 {
    padding-left: var(--spacing-0);
}

.inf-pl-0p5 {
    padding-left: var(--spacing-0p5);
}

.inf-pl-1 {
    padding-left: var(--spacing-1);
}

.inf-pl-1p5 {
    padding-left: var(--spacing-1p5);
}

.inf-pl-2 {
    padding-left: var(--spacing-2);
}

.inf-pl-2p5 {
    padding-left: var(--spacing-2p5);
}

.inf-pl-3 {
    padding-left: var(--spacing-3);
}

.inf-pl-3p5 {
    padding-left: var(--spacing-3p5);
}

.inf-pl-4 {
    padding-left: var(--spacing-4);
}

.inf-pl-5 {
    padding-left: var(--spacing-5);
}

.inf-pl-6 {
    padding-left: var(--spacing-6);
}

.inf-pl-7 {
    padding-left: var(--spacing-7);
}

.inf-pl-8 {
    padding-left: var(--spacing-8);
}

.inf-pl-9 {
    padding-left: var(--spacing-9);
}

.inf-pl-10 {
    padding-left: var(--spacing-10);
}

.inf-pl-11 {
    padding-left: var(--spacing-11);
}

.inf-pl-12 {
    padding-left: var(--spacing-12);
}

.inf-pl-14 {
    padding-left: var(--spacing-14);
}

.inf-pl-16 {
    padding-left: var(--spacing-16);
}

.inf-pl-20 {
    padding-left: var(--spacing-20);
}

.inf-pl-24 {
    padding-left: var(--spacing-24);
}

.inf-pl-28 {
    padding-left: var(--spacing-28);
}

.inf-pl-32 {
    padding-left: var(--spacing-32);
}

.inf-pl-36 {
    padding-left: var(--spacing-36);
}

.inf-pl-40 {
    padding-left: var(--spacing-40);
}

.inf-pl-44 {
    padding-left: var(--spacing-44);
}

.inf-pl-48 {
    padding-left: var(--spacing-48);
}

.inf-pl-52 {
    padding-left: var(--spacing-52);
}

.inf-pl-56 {
    padding-left: var(--spacing-56);
}

.inf-pl-60 {
    padding-left: var(--spacing-60);
}

.inf-pl-64 {
    padding-left: var(--spacing-64);
}

.inf-pl-72 {
    padding-left: var(--spacing-72);
}

.inf-pl-80 {
    padding-left: var(--spacing-80);
}

.inf-pl-96 {
    padding-left: var(--spacing-96);
}

/* Padding-right classes based on spacing variables */
.inf-pr-0 {
    padding-right: var(--spacing-0);
}

.inf-pr-0p5 {
    padding-right: var(--spacing-0p5);
}

.inf-pr-1 {
    padding-right: var(--spacing-1);
}

.inf-pr-1p5 {
    padding-right: var(--spacing-1p5);
}

.inf-pr-2 {
    padding-right: var(--spacing-2);
}

.inf-pr-2p5 {
    padding-right: var(--spacing-2p5);
}

.inf-pr-3 {
    padding-right: var(--spacing-3);
}

.inf-pr-3p5 {
    padding-right: var(--spacing-3p5);
}

.inf-pr-4 {
    padding-right: var(--spacing-4);
}

.inf-pr-5 {
    padding-right: var(--spacing-5);
}

.inf-pr-6 {
    padding-right: var(--spacing-6);
}

.inf-pr-7 {
    padding-right: var(--spacing-7);
}

.inf-pr-8 {
    padding-right: var(--spacing-8);
}

.inf-pr-9 {
    padding-right: var(--spacing-9);
}

.inf-pr-10 {
    padding-right: var(--spacing-10);
}

.inf-pr-11 {
    padding-right: var(--spacing-11);
}

.inf-pr-12 {
    padding-right: var(--spacing-12);
}

.inf-pr-14 {
    padding-right: var(--spacing-14);
}

.inf-pr-16 {
    padding-right: var(--spacing-16);
}

.inf-pr-20 {
    padding-right: var(--spacing-20);
}

.inf-pr-24 {
    padding-right: var(--spacing-24);
}

.inf-pr-28 {
    padding-right: var(--spacing-28);
}

.inf-pr-32 {
    padding-right: var(--spacing-32);
}

.inf-pr-36 {
    padding-right: var(--spacing-36);
}

.inf-pr-40 {
    padding-right: var(--spacing-40);
}

.inf-pr-44 {
    padding-right: var(--spacing-44);
}

.inf-pr-48 {
    padding-right: var(--spacing-48);
}

.inf-pr-52 {
    padding-right: var(--spacing-52);
}

.inf-pr-56 {
    padding-right: var(--spacing-56);
}

.inf-pr-60 {
    padding-right: var(--spacing-60);
}

.inf-pr-64 {
    padding-right: var(--spacing-64);
}

.inf-pr-72 {
    padding-right: var(--spacing-72);
}

.inf-pr-80 {
    padding-right: var(--spacing-80);
}

.inf-pr-96 {
    padding-right: var(--spacing-96);
}

/* Padding-top classes based on spacing variables */
.inf-pt-0 {
    padding-top: var(--spacing-0);
}

.inf-pt-0p5 {
    padding-top: var(--spacing-0p5);
}

.inf-pt-1 {
    padding-top: var(--spacing-1);
}

.inf-pt-1p5 {
    padding-top: var(--spacing-1p5);
}

.inf-pt-2 {
    padding-top: var(--spacing-2);
}

.inf-pt-2p5 {
    padding-top: var(--spacing-2p5);
}

.inf-pt-3 {
    padding-top: var(--spacing-3);
}

.inf-pt-3p5 {
    padding-top: var(--spacing-3p5);
}

.inf-pt-4 {
    padding-top: var(--spacing-4);
}

.inf-pt-5 {
    padding-top: var(--spacing-5);
}

.inf-pt-6 {
    padding-top: var(--spacing-6);
}

.inf-pt-7 {
    padding-top: var(--spacing-7);
}

.inf-pt-8 {
    padding-top: var(--spacing-8);
}

.inf-pt-9 {
    padding-top: var(--spacing-9);
}

.inf-pt-10 {
    padding-top: var(--spacing-10);
}

.inf-pt-11 {
    padding-top: var(--spacing-11);
}

.inf-pt-12 {
    padding-top: var(--spacing-12);
}

.inf-pt-14 {
    padding-top: var(--spacing-14);
}

.inf-pt-16 {
    padding-top: var(--spacing-16);
}

.inf-pt-20 {
    padding-top: var(--spacing-20);
}

.inf-pt-24 {
    padding-top: var(--spacing-24);
}

.inf-pt-28 {
    padding-top: var(--spacing-28);
}

.inf-pt-32 {
    padding-top: var(--spacing-32);
}

.inf-pt-36 {
    padding-top: var(--spacing-36);
}

.inf-pt-40 {
    padding-top: var(--spacing-40);
}

.inf-pt-44 {
    padding-top: var(--spacing-44);
}

.inf-pt-48 {
    padding-top: var(--spacing-48);
}

.inf-pt-52 {
    padding-top: var(--spacing-52);
}

.inf-pt-56 {
    padding-top: var(--spacing-56);
}

.inf-pt-60 {
    padding-top: var(--spacing-60);
}

.inf-pt-64 {
    padding-top: var(--spacing-64);
}

.inf-pt-72 {
    padding-top: var(--spacing-72);
}

.inf-pt-80 {
    padding-top: var(--spacing-80);
}

.inf-pt-96 {
    padding-top: var(--spacing-96);
}

/* Padding-bottom classes based on spacing variables */
.inf-pb-0 {
    padding-bottom: var(--spacing-0);
}

.inf-pb-0p5 {
    padding-bottom: var(--spacing-0p5);
}

.inf-pb-1 {
    padding-bottom: var(--spacing-1);
}

.inf-pb-1p5 {
    padding-bottom: var(--spacing-1p5);
}

.inf-pb-2 {
    padding-bottom: var(--spacing-2);
}

.inf-pb-2p5 {
    padding-bottom: var(--spacing-2p5);
}

.inf-pb-3 {
    padding-bottom: var(--spacing-3);
}

.inf-pb-3p5 {
    padding-bottom: var(--spacing-3p5);
}

.inf-pb-4 {
    padding-bottom: var(--spacing-4);
}

.inf-pb-5 {
    padding-bottom: var(--spacing-5);
}

.inf-pb-6 {
    padding-bottom: var(--spacing-6);
}

.inf-pb-7 {
    padding-bottom: var(--spacing-7);
}

.inf-pb-8 {
    padding-bottom: var(--spacing-8);
}

.inf-pb-9 {
    padding-bottom: var(--spacing-9);
}

.inf-pb-10 {
    padding-bottom: var(--spacing-10);
}

.inf-pb-11 {
    padding-bottom: var(--spacing-11);
}

.inf-pb-12 {
    padding-bottom: var(--spacing-12);
}

.inf-pb-14 {
    padding-bottom: var(--spacing-14);
}

.inf-pb-16 {
    padding-bottom: var(--spacing-16);
}

.inf-pb-20 {
    padding-bottom: var(--spacing-20);
}

.inf-pb-24 {
    padding-bottom: var(--spacing-24);
}

.inf-pb-28 {
    padding-bottom: var(--spacing-28);
}

.inf-pb-32 {
    padding-bottom: var(--spacing-32);
}

.inf-pb-36 {
    padding-bottom: var(--spacing-36);
}

.inf-pb-40 {
    padding-bottom: var(--spacing-40);
}

.inf-pb-44 {
    padding-bottom: var(--spacing-44);
}

.inf-pb-48 {
    padding-bottom: var(--spacing-48);
}

.inf-pb-52 {
    padding-bottom: var(--spacing-52);
}

.inf-pb-56 {
    padding-bottom: var(--spacing-56);
}

.inf-pb-60 {
    padding-bottom: var(--spacing-60);
}

.inf-pb-64 {
    padding-bottom: var(--spacing-64);
}

.inf-pb-72 {
    padding-bottom: var(--spacing-72);
}

.inf-pb-80 {
    padding-bottom: var(--spacing-80);
}

.inf-pb-96 {
    padding-bottom: var(--spacing-96);
}

/* Horizontal Padding */
.inf-px-0 {
    padding-left: var(--spacing-0);
    padding-right: var(--spacing-0);
}

.inf-px-0p5 {
    padding-left: var(--spacing-0p5);
    padding-right: var(--spacing-0p5);
}

.inf-px-1 {
    padding-left: var(--spacing-1);
    padding-right: var(--spacing-1);
}

.inf-px-1p5 {
    padding-left: var(--spacing-1p5);
    padding-right: var(--spacing-1p5);
}

.inf-px-2 {
    padding-left: var(--spacing-2);
    padding-right: var(--spacing-2);
}

.inf-px-2p5 {
    padding-left: var(--spacing-2p5);
    padding-right: var(--spacing-2p5);
}

.inf-px-3 {
    padding-left: var(--spacing-3);
    padding-right: var(--spacing-3);
}

.inf-px-3p5 {
    padding-left: var(--spacing-3p5);
    padding-right: var(--spacing-3p5);
}

.inf-px-4 {
    padding-left: var(--spacing-4);
    padding-right: var(--spacing-4);
}

.inf-px-5 {
    padding-left: var(--spacing-5);
    padding-right: var(--spacing-5);
}

.inf-px-6 {
    padding-left: var(--spacing-6);
    padding-right: var(--spacing-6);
}

.inf-px-7 {
    padding-left: var(--spacing-7);
    padding-right: var(--spacing-7);
}

.inf-px-8 {
    padding-left: var(--spacing-8);
    padding-right: var(--spacing-8);
}

.inf-px-9 {
    padding-left: var(--spacing-9);
    padding-right: var(--spacing-9);
}

.inf-px-10 {
    padding-left: var(--spacing-10);
    padding-right: var(--spacing-10);
}

.inf-px-11 {
    padding-left: var(--spacing-11);
    padding-right: var(--spacing-11);
}

.inf-px-12 {
    padding-left: var(--spacing-12);
    padding-right: var(--spacing-12);
}

.inf-px-14 {
    padding-left: var(--spacing-14);
    padding-right: var(--spacing-14);
}

.inf-px-16 {
    padding-left: var(--spacing-16);
    padding-right: var(--spacing-16);
}

.inf-px-20 {
    padding-left: var(--spacing-20);
    padding-right: var(--spacing-20);
}

.inf-px-24 {
    padding-left: var(--spacing-24);
    padding-right: var(--spacing-24);
}

.inf-px-28 {
    padding-left: var(--spacing-28);
    padding-right: var(--spacing-28);
}

.inf-px-32 {
    padding-left: var(--spacing-32);
    padding-right: var(--spacing-32);
}

.inf-px-36 {
    padding-left: var(--spacing-36);
    padding-right: var(--spacing-36);
}

.inf-px-40 {
    padding-left: var(--spacing-40);
    padding-right: var(--spacing-40);
}

.inf-px-44 {
    padding-left: var(--spacing-44);
    padding-right: var(--spacing-44);
}

.inf-px-48 {
    padding-left: var(--spacing-48);
    padding-right: var(--spacing-48);
}

.inf-px-52 {
    padding-left: var(--spacing-52);
    padding-right: var(--spacing-52);
}

.inf-px-56 {
    padding-left: var(--spacing-56);
    padding-right: var(--spacing-56);
}

.inf-px-60 {
    padding-left: var(--spacing-60);
    padding-right: var(--spacing-60);
}

.inf-px-64 {
    padding-left: var(--spacing-64);
    padding-right: var(--spacing-64);
}

.inf-px-72 {
    padding-left: var(--spacing-72);
    padding-right: var(--spacing-72);
}

.inf-px-80 {
    padding-left: var(--spacing-80);
    padding-right: var(--spacing-80);
}

.inf-px-96 {
    padding-left: var(--spacing-96);
    padding-right: var(--spacing-96);
}

/* Vertical Padding */
.inf-py-0 {
    padding-top: var(--spacing-0);
    padding-bottom: var(--spacing-0);
}

.inf-py-0p5 {
    padding-top: var(--spacing-0p5);
    padding-bottom: var(--spacing-0p5);
}

.inf-py-1 {
    padding-top: var(--spacing-1);
    padding-bottom: var(--spacing-1);
}

.inf-py-1p5 {
    padding-top: var(--spacing-1p5);
    padding-bottom: var(--spacing-1p5);
}

.inf-py-2 {
    padding-top: var(--spacing-2);
    padding-bottom: var(--spacing-2);
}

.inf-py-2p5 {
    padding-top: var(--spacing-2p5);
    padding-bottom: var(--spacing-2p5);
}

.inf-py-3 {
    padding-top: var(--spacing-3);
    padding-bottom: var(--spacing-3);
}

.inf-py-3p5 {
    padding-top: var(--spacing-3p5);
    padding-bottom: var(--spacing-3p5);
}

.inf-py-4 {
    padding-top: var(--spacing-4);
    padding-bottom: var(--spacing-4);
}

.inf-py-5 {
    padding-top: var(--spacing-5);
    padding-bottom: var(--spacing-5);
}

.inf-py-6 {
    padding-top: var(--spacing-6);
    padding-bottom: var(--spacing-6);
}

.inf-py-7 {
    padding-top: var(--spacing-7);
    padding-bottom: var(--spacing-7);
}

.inf-py-8 {
    padding-top: var(--spacing-8);
    padding-bottom: var(--spacing-8);
}

.inf-py-9 {
    padding-top: var(--spacing-9);
    padding-bottom: var(--spacing-9);
}

.inf-py-10 {
    padding-top: var(--spacing-10);
    padding-bottom: var(--spacing-10);
}

.inf-py-11 {
    padding-top: var(--spacing-11);
    padding-bottom: var(--spacing-11);
}

.inf-py-12 {
    padding-top: var(--spacing-12);
    padding-bottom: var(--spacing-12);
}

.inf-py-14 {
    padding-top: var(--spacing-14);
    padding-bottom: var(--spacing-14);
}

.inf-py-16 {
    padding-top: var(--spacing-16);
    padding-bottom: var(--spacing-16);
}

.inf-py-20 {
    padding-top: var(--spacing-20);
    padding-bottom: var(--spacing-20);
}

.inf-py-24 {
    padding-top: var(--spacing-24);
    padding-bottom: var(--spacing-24);
}

.inf-py-28 {
    padding-top: var(--spacing-28);
    padding-bottom: var(--spacing-28);
}

.inf-py-32 {
    padding-top: var(--spacing-32);
    padding-bottom: var(--spacing-32);
}

.inf-py-36 {
    padding-top: var(--spacing-36);
    padding-bottom: var(--spacing-36);
}

.inf-py-40 {
    padding-top: var(--spacing-40);
    padding-bottom: var(--spacing-40);
}

.inf-py-44 {
    padding-top: var(--spacing-44);
    padding-bottom: var(--spacing-44);
}

.inf-py-48 {
    padding-top: var(--spacing-48);
    padding-bottom: var(--spacing-48);
}

.inf-py-52 {
    padding-top: var(--spacing-52);
    padding-bottom: var(--spacing-52);
}

.inf-py-56 {
    padding-top: var(--spacing-56);
    padding-bottom: var(--spacing-56);
}

.inf-py-60 {
    padding-top: var(--spacing-60);
    padding-bottom: var(--spacing-60);
}

.inf-py-64 {
    padding-top: var(--spacing-64);
    padding-bottom: var(--spacing-64);
}

.inf-py-72 {
    padding-top: var(--spacing-72);
    padding-bottom: var(--spacing-72);
}

.inf-py-80 {
    padding-top: var(--spacing-80);
    padding-bottom: var(--spacing-80);
}

.inf-py-96 {
    padding-top: var(--spacing-96);
    padding-bottom: var(--spacing-96);
}

/* Font Sizes */
.inf-text-xs {
    font-size: 0.75rem;
    /* 12px */
    line-height: 1rem;
    /* 16px */
}

.inf-text-sm {
    font-size: 0.875rem;
    /* 14px */
    line-height: 1.25rem;
    /* 20px */
}

.inf-text-base {
    font-size: 1rem;
    /* 16px */
    line-height: 1.5rem;
    /* 24px */
}

.inf-text-lg {
    font-size: 1.125rem;
    /* 18px */
    line-height: 1.75rem;
    /* 28px */
}

.inf-text-xl {
    font-size: 1.25rem;
    /* 20px */
    line-height: 1.75rem;
    /* 28px */
}

.inf-text-2xl {
    font-size: 1.5rem;
    /* 24px */
    line-height: 2rem;
    /* 32px */
}

.inf-text-3xl {
    font-size: 1.875rem;
    /* 30px */
    line-height: 2.25rem;
    /* 36px */
}

.inf-text-4xl {
    font-size: 2.25rem;
    /* 36px */
    line-height: 2.5rem;
    /* 40px */
}

.inf-text-5xl {
    font-size: 3rem;
    /* 48px */
    line-height: 1;
}

.inf-text-6xl {
    font-size: 3.75rem;
    /* 60px */
    line-height: 1;
}

.inf-text-7xl {
    font-size: 4.5rem;
    /* 72px */
    line-height: 1;
}

.inf-text-8xl {
    font-size: 6rem;
    /* 96px */
    line-height: 1;
}

.inf-text-9xl {
    font-size: 8rem;
    /* 128px */
    line-height: 1;
}

/* Font Weights */
.inf-font-thin {
    font-weight: 100;
}

.inf-font-extralight {
    font-weight: 200;
}

.inf-font-light {
    font-weight: 300;
}

.inf-font-normal {
    font-weight: 400;
}

.inf-font-medium {
    font-weight: 500;
}

.inf-font-semibold {
    font-weight: 600;
}

.inf-font-bold {
    font-weight: 700;
}

.inf-font-extrabold {
    font-weight: 800;
}

.inf-font-thick {
    font-weight: 900;
}

/* Flex Classes */

.inf-flex {
    display: flex;
}

.inf-flex-col {
    flex-direction: column;
}

.inf-flex-wrap {
    flex-wrap: wrap;
}

.inf-justify-end {
    justify-content: end;
}

.inf-justify-center {
    justify-content: center;
}

.inf-justify-between {
    justify-content: space-between;
}

.inf-items-center {
    align-items: center;
}

.inf-gap-0 {
    --inf-flex-grid-gap: 0rem;
    gap: var(--inf-flex-grid-gap, 0rem)
}

.inf-gap-1 {
    --inf-flex-grid-gap: 0.25rem;
    gap: var(--inf-flex-grid-gap, 0.25rem)
}

.inf-gap-2 {
    --inf-flex-grid-gap: 0.5rem;
    gap: var(--inf-flex-grid-gap, 0.5rem)
}

.inf-gap-3 {
    --inf-flex-grid-gap: 0.75rem;
    gap: var(--inf-flex-grid-gap, 0.75rem)
}

.inf-gap-4 {
    --inf-flex-grid-gap: 1rem;
    gap: var(--inf-flex-grid-gap, 1rem)
}

.inf-gap-5 {
    --inf-flex-grid-gap: 1.25rem;
    gap: var(--inf-flex-grid-gap, 1.25rem)
}

.inf-gap-6 {
    --inf-flex-grid-gap: 1.5rem;
    gap: var(--inf-flex-grid-gap, 1.5rem)
}

.inf-gap-7 {
    --inf-flex-grid-gap: 1.75rem;
    gap: var(--inf-flex-grid-gap, 1.75rem)
}

.inf-gap-8 {
    --inf-flex-grid-gap: 2rem;
    gap: var(--inf-flex-grid-gap, 2rem)
}

.inf-gap-9 {
    --inf-flex-grid-gap: 2.25rem;
    gap: var(--inf-flex-grid-gap, 2.25rem)
}

.inf-gap-10 {
    --inf-flex-grid-gap: 2.5rem;
    gap: var(--inf-flex-grid-gap, 2.5rem)
}

.inf-gap-11 {
    --inf-flex-grid-gap: 2.75rem;
    gap: var(--inf-flex-grid-gap, 2.75rem)
}

.inf-gap-12 {
    --inf-flex-grid-gap: 3rem;
    gap: var(--inf-flex-grid-gap, 3rem)
}

/* Text Alignment */
.inf-text-left {
    text-align: left;
}

.inf-text-center {
    text-align: center;
}

.inf-text-right {
    text-align: right;
}

.inf-text-justify {
    text-align: justify;
}

.inf-text-start {
    text-align: start;
}

.inf-text-end {
    text-align: end;
}

/* Bounding box */
.inf-w-full {
    width: 100%;
}

.inf-h-full {
    height: 100%;
}

/* Border Radius */
.rounded	{
    border-radius: 0.25rem;
}

.rounded-md	{
    border-radius: 0.375rem;
}

.rounded-lg	{
    border-radius: 0.5rem;
}

.rounded-xl    {
    border-radius: 0.75rem;
}

.rounded-2xl    {
    border-radius: 1rem;
}

.rounded-full	{
    border-radius: 9999px;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-visible {
    overflow: visible;
}

.overflow-scroll {
    overflow: scroll;
}

.overflow-auto {
    overflow: auto;
}