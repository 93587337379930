.addon_card {
    border-radius: 6.769px;
    border: 1px solid #A7A8B2;
    background: #FAFBFB;
    display: flex;
    flex-direction: column;
}

.addon_card.addon_subscibed{
    border: 1px solid #23C16B
}

.addon_name {
    color: #000;
    text-align: center;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 16px 0px;
}

.addon_pricing {
    background: #4597F7;
    color: #FFF;
    font-size: 14px;
    font-weight: 700;
    min-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addon_subscibed .addon_pricing{
    background: #429C6C;
}

.addon_features {
    border-radius: 6.769px;
    background: var(--System-colors-System-Colors-Grey-4, #C5C7CF48);
    flex: 1;
    margin: 1rem;
    padding-right: 1.5rem;
}

.inf_share_user_modal_infurnia_user_badge{
    font-size: 10px;
    padding: 2px 4px;
    background-color: #3A63D420;
    color: #3A63D4;
    border-radius: 2px;
    /* color: #3A63D4; */
}