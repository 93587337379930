.hover_to_gray2:hover {
    background-color: #f5f6f8;
}

#panel_scroll_container_popup {
    border-right: 1px solid var(--inf-highlight-background-color, #788394);
    font-family: 'DM Sans', sans-serif;
  }
  
#panel_scroll_container_popup::-webkit-scrollbar {
  width: 1.5px;
}

#panel_scroll_container_popup::-webkit-scrollbar-track {
  box-shadow: none;
}

.header_icon_container_panel_popup{
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.header_icon_container_panel_popup img {
  width: 16px;
}

.active_panel_pop_up_search{
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: white;
  cursor: pointer;
}
