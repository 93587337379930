.cam_applicator_card{
	padding: 12px 12px 0px 12px;
	background: white;
	min-width: 0px;
	cursor: pointer;
	border-radius: 4px;
	display: flex ;
	flex-flow: column ;
	gap: 8px
}

.rule_applicator_card{
	display: flex;
	align-items: center;
	gap: 32px;
	padding: 16px 32px;
}

.rule_applicator_card_info{
	align-self: baseline;
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 320px;
}

.rule_applicator_card_sku_info{
	display: flex;
	flex-direction: column;
	align-items: center;
}

.cam_applicator_input{
	border: 1px solid #a7a8b3;
	padding: 4px 8px;
	border-radius: 4px;
	outline: none;
	box-shadow: none;
}

.cam_applicator_input:focus{
	border: 1px solid #0078ff;
}