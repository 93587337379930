.inf_single_select_toggle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 8px;
    font-size: 12px;
    width: 100%;
}

.inf_single_select_menu.show{
    max-height: 400px;
    overflow: auto;
    background-color: white;
    padding: 0px 4px 4px 4px;
}

.inf_single_select_menu.dropdown-menu.show{
    max-height: 400px;
    overflow: auto;
    background-color: white;
    padding: 0px 4px 4px 4px;
}

.inf_single_select_item{
    padding: 6px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.inf_single_select_item:hover{
    background-color: var(--inf-theme-gray-300);
}

.inf_single_select_search_component{
    position: sticky;
    top: 0;
    background: white;
    padding: 4px 0px;
}

.single_select_selected_item{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}