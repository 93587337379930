
.inf_carousel_card_shine{
    /* background: linear-gradient(120deg, #31323A 8%, #60616a 10%, #31323A 33%); */
    animation: carousel_shine 2s ease-in-out infinite;
    /* background-size: 200% 100%; */
}

@keyframes carousel_shine {
   0%, 100% {
    background-color: #31323A;
    /* opacity: 1; */
   }
   50% {
    background-color: #26272C;
    /* opacity: 0.5; */
   }
}