.MuiOutlinedInput-input{
	padding:8px !important
}
.card_style{
	background: #FFFFFF;
	border: 1px solid #D5DAE0;
	border-radius: 4px;
}

.sub_category_active {
	background-color:rgba(16,112,202,0.09);
	color:#1070CA !important;
	font-family: Source_Sans_Pro-SemiBold;
}

.division_hover:hover {
	color:#1070CA !important;
}
.division_active {
	background-image: linear-gradient(to right, rgba(16,112,202,0.09) 99%, #1070CA 2%);
	color:black !important;
}

.sku_dropdown{
	background-color: #FFFFFF;
	background-image: linear-gradient(0deg, #F4F5F7 0%, #FFFFFF 100%);
	box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.09), inset 0 0 0 1px rgba(67,90,111,0.14) !important;
	border-radius: 3px !important;
	height:24px;
	width:24px;
	display: flex !important;
	align-items: center;
	padding:0 !important;
	margin: 0 !important;
	justify-content: center;
	margin-left: 6px !important;
	border: 0;
	font-size: 12px;
	color:#66788A; 
}

.sku_dropdown_list{
	background: #FFFFFF;
	box-shadow: 0 0 1px 0 rgba(67,90,111,0.47);
	border-radius: 5px;
	font-size: 14px;
}

.sku_dropdown_list_item{
	padding:6px 12px;
}

.group_dropdown{
	border: 1px solid #D1D7DA;
	background-color: #ffffff ;
	border-radius: 3px;
	box-shadow: none !important;
	height:27px;
	width:27px;
	display: flex !important;
	align-items: center;
	padding:0 !important;
	margin: 0 !important;
	justify-content: center;
	font-size: 12px;
}

.card_header_2{
	background: rgba(16,112,202,0.08);
	border-radius: 3px;
}

.card_select{
	background: #FFFFFF;
	background-image: linear-gradient(0deg, #F4F5F7 0%, #FFFFFF 100%);
	box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.09), inset 0 0 0 1px rgba(67,90,111,0.14);
	border-radius: 3px !important;
    padding: 0px !important;
    height: 24px !important;
    font-size: 12px !important;
    border: 0
}
/*card hover*/
.on_hover_card_border:hover{
	border: 1px solid rgba(0,126,244,0.32);
}

.on_hover_card_border:hover .hover_1 .hover_2{
	color:#007EF4 !important;
	display: block !important;
}

.on_hover_card_border:hover .hover_1 .hover_2 .hover_3{
	color:#007EF4 !important;
	background: rgba(16,112,202,0.08);
	border-radius: 3px;
	border: none !important;
	display: block !important;
}


.on_hover_card_border:hover .hover_1 .hover_2 .hover_4{
	background-color: rgba(16,112,202,0.08) !important;
	background-image: unset !important;
}

.on_hover_card_border:hover .hover_1 .hover_2 .hover_x3{
	color:#007EF4 !important;
	display: block !important;
}

/*list hover*/
.on_hover_card_border_list:hover{
	background: rgba(0,126,244,0.06);
}

.on_hover_card_border_list:hover .hover_1 .hover_2 .hover_x3{
	color:#007EF4 !important;
	display: block !important;
}
.on_hover_card_border_list:hover .hover_1 .hover_2 .hover_3{
	color:#007EF4 !important;
	background: rgba(16,112,202,0.08);
	border-radius: 3px;
	border: none !important;
}

.on_hover_card_border_list:hover .hover_1 .hover_2 .hover_4{
	background-color: rgba(16,112,202,0.08) !important;
	background-image: unset !important;
}


/*table tr,td*/

.list_table_tr{
	width:100%;
	border: 1px solid #F2F4F6;
	box-shadow: inset 0 -1px 0 0 #F2F4F6;
	display:flex;
	align-items:center;
	justify-content: flex-start;
	
}
.list_table_td{
	width:100%;
	height:50px;
	display:flex;
	align-items:center;
	justify-content: flex-start;
	overflow-x: auto;
	white-space: nowrap;
}

.card_size_for_sku_properties{
	height:586px !important;
	width:700px !important;
}

/*for all checkbox*/
.checkbox_style{
	background: #FFFFFF !important;
	background-image: linear-gradient(179deg, #FFFFFF 0%, #F5F6F7 100%) !important;
	box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.09), inset 0 0 0 1px rgba(67,90,111,0.14) !important;
	border-radius: 3px !important;
}

.edit_properties_dropdown{
	background: #FFFFFF;
	background-image: linear-gradient(0deg, #F4F5F7 0%, #FFFFFF 100%);
	box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.09), inset 0 0 0 1px rgba(67,90,111,0.14);
	border-radius: 3px;
	width: 100%;
	font-size: 12px;
	color:#66788A;
}

.form-group{
	margin-bottom: 0;
}
/*.label_margin_5px{
	margin-bottom: 3px;

}*/

.disabled_css{
	background-color: #EEEEEE !important;
}