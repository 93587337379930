.btn-google {
    color: #fff;
    background-color: #dd4b39;
    border-color: rgba(0,0,0,0.2);
}

.btn-social {
    position: relative;
    padding-left: 44px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.btn-block {
    display: block;
    width: 100%;
}

.btn_login{
    font-size: 14px !important;
    height: 34px;
    display: flex;
    align-items: center;
    width:100%;
}

.input_login{
    height:35px !important;
    font-size: 14px !important;
}

.btn_google:hover {
    color: #fff !important;
    background-color: #c23321 !important;
    border-color: rgba(0,0,0,0.2) !important;
}
.btn_linkedin:hover {
    color: #fff !important;
    background-color: #005983 !important;
    border-color: rgba(0,0,0,0.2) !important;
}

.forgot_password_hover:hover{
    color: #1634dd !important;
}
/* , .btn-success:focus, .btn-success:active, .btn-success.active, .open .dropdown-toggle.btn-success  */
.btn_success:hover{
    background-color: #1ABB9C !important;
    border-color: #1ABB9C i !important;
    color: #FFFFFF i !important;
}
.btn_default:hover {
    color: #333 i !important;
    background-color: #e6e6e6 !important;
    border-color: #adadad i !important;
}
.login_icons{
    width: 32px;
    line-height: 34px;
    text-align: center;
    border-right: 1px solid rgba(0,0,0,0.2);
}

.sign_up_text_color{
    color:#73879C ;
    font-size:13px;
    font-weight: bold;
}

/* store options style */
.store_options_card{
    width: 350px;
    height: auto;
    position: absolute;
    left: calc((100% - 350px) / 2);
    top: 5%;
}

.all_store_options_div{
    overflow: auto;
    height:430px;
    padding:20px;
}

.single_store{
    height:50px;
    padding:0px 20px;
    margin-bottom:5px;
    background-color:#f9f9f9;
    font-size:14px;
    display:flex;
    align-items:center;
}

.single_store:hover{
    background-color:#f2f2f2 !important;
}

.single_store_text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width:100%;
    text-transform: capitalize;
}
.single_store_icon{
    font-size: 20px;
    margin-right: 10px;
    color:#404040;
}