body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}

.card-img{
	width:90% !important;
}

.black_button_default {
  background-color: rgba(50, 51, 56, 1) !important;
  color: white !important;
  border: 1px solid rgba(50, 51, 56, 1) !important;
  padding: 0px 16px !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px !important;
  font-size: 14px !important;
  box-shadow: unset;
}

.black_button_default:focus{
  box-shadow: unset !important;
}

.secondary_button_default {
  background-color: white !important;
  color: rgba(50, 51, 56, 1) !important;
  border: 1px solid #C5C7CF !important;
  padding: 0px 16px !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px !important;
  font-size: 14px !important;
  box-shadow: unset;
}

.secondary_button_default:focus{
  box-shadow: unset !important;
}

.secondary_button_ghost {
  background-color: transparent !important;
  color: rgba(50, 51, 56, 1) !important;
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: unset;
  padding: 4px 8px !important;
  cursor: pointer;
}

.secondary_button_ghost:focus{
  box-shadow: unset !important;
}


.primary_button_default {
  background-color: #0078ff !important;
  color: white !important;
  border: 1px solid #4597F7 !important;
  padding: 0px 16px !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px !important;
  font-size: 14px !important;
  box-shadow: unset;
}

.primary_button_default:focus{
  box-shadow: unset !important;
}

.primary_button_ghost {
  background-color: transparent !important;
  color: #0078ff !important;
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: unset;
  padding: 4px 8px !important;
  cursor: pointer;
}

.primary_button_ghost:focus{
  box-shadow: unset !important;
}

.red_button_default {
  background-color: rgba(249, 218, 222, 1) !important;
  color: rgba(255, 82, 71, 1) !important;
  border: 1px solid rgba(255, 82, 71, 1) !important;
  padding: 0px 16px !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px !important;
  font-size: 14px !important;
  box-shadow: unset;
}

.red_button_default:focus{
  box-shadow: unset !important;
}

.red_button_default_ghost {
  background-color: transparent !important;
  color: rgba(255, 82, 71, 1) !important;
  box-shadow: unset;
  cursor: pointer;
}

.zero_padding {
  padding: 0px !important;
}

.flex_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex_property{
  display: flex;
  align-items: center;
}

.flex_property_wrap{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.flex_column{
  display: flex;
  flex-direction: column;
}

.hover_left_menu:hover {
  background-color: #F5F6F8;
  border-radius: 10px;
}

.hover_toolbar:hover {
  background-color: var(--secondary-background-design-navbar, #a7a8b340);
  /* border-radius: 10px; */
}

.toolbar_active {
  background-color: #E6E9EE;
  /* border-radius: 10px; */
}

.left16 {
  left: 16px !important;
}

.left44 {
  left: -44px !important;
}

.global_modal_header{
  height:40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #E6E9EE;
  background-color: white;
  color:black;
  font-weight: 700;
  font-size: 16px;
}

.global_modal_footer{
  height:50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color:#FFFFFF;
}

#tooltip1
{
  position: absolute;
  left: 0px;
  top: 0px;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 12px;
  
  display: inline-block;
}

#tooltip1.right
{
  margin-left: 5px;
}

#tooltip1.left
{
  margin-left: -5px;
}

#tooltip1.top
{
  margin-top: -5px;
}

#tooltip1.bottom
{
  margin-top: 30px;
}

#tooltip1 .tooltip-arrow
{
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}

#tooltip1.right .tooltip-arrow
{
  top: 50%;
  left: auto;
  margin-left: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}



#tooltip1.top .tooltip-arrow
{
  top: auto;
  bottom: -5px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

#tooltip1.left .tooltip-arrow
{
  top: 50%;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
  right: -5px;
  left: auto;
}

#tooltip1.bottom .tooltip-arrow
{
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

#tooltip1 .tooltip-arrow
{
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-right-color: transparent;
  border-style: solid;
}

#tooltip1 .tooltip-label
{
  max-width: 500px;
  height: 40px;
  min-width:150px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}

.display_onhover:hover{
  box-shadow: inset 0 -1px 0 0 #F2F4F6;
  background-color: rgba(24, 144, 255, 0.06) !important;
}

.invisible1{
	visibility: hidden;
}

.display_onhover:hover .x1 .invisible1{
display:flex;
visibility:visible;
color: #1890ff;
}

.delete_button{
  border: 1px #f5594e solid;
  padding: 6px 8px;
  border-radius: 4px;
}

.delete_button:hover{
  opacity: 0.7;
}

.loader {
  --height-of-loader: 4px;
  --loader-color: #0071e2;
  width: 60%;
  height: var(--height-of-loader);
  border-radius: 30px;
  background-color: rgba(0,0,0,0.2);
  position: relative;
 }
 
 .loader::before {
  content: "";
  position: absolute;
  background: var(--loader-color);
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  border-radius: 30px;
  animation: moving 3s ease-in-out infinite;
  ;
 }
 
 @keyframes moving {
  50% {
   width: 100%;
  }
 
  100% {
   width: 0;
   right: 0;
   left: unset;
  }
 }

 :root {
  --panel_tree_container_background: #F5F6F8;
  --bottom_status_bar_offset_on_logo: 10px;
  --navbar_height_design: 52px;
  --ribbon_tabs_height: 24px;
  --toolbar_height: 60px;
  --panel_width: 270px;
  --panel_footer_height: 42px;
  --blue6: #0078ff;
  --red_default: #FF5247;

  --inf-canvas-height: calc( 100vh - var(--navbar_height_design, 52px) - var(--ribbon_tabs_height, 24px) - var(--toolbar_height, 60px) );

  --inf-bottom-carousel-height: 170px;
  --inf-bottom-carousel-nav-height: 42px;
  --inf-bottom-carousel-card-height: 74px;

  --infurnia-navbar-height: 3.25rem;
  --infurnia-sidebar-width: 15rem;

  --inf-highlight-background-color: #788394;

 }

.mes_design_edit_mode{
  --inf-highlight-background-color: #4C4D52;
 }

 .js-c{
  justify-content: center;
 }

 .js-s{
  justify-content: flex-start;
 }

 .js-e{
  justify-content: flex-end;
 }

 .js-sa{
  justify-content: space-around;
 }

 .js-sb{
  justify-content: space-between;
 }

 .js-se{
  justify-content: space-evenly;
 }
 
 .modal-body{
  background-color: #F5F6F8;
 }

 .modal-footer{
  box-shadow: 0px -1px 6px 0px #0000000D;
 }

 .modal-header{
  height: 48px;
  align-items: center !important;
 }
 
 .scale_120 {
  transition: 0.3s ease-in;
}

.scale_120:hover {
  transform: scale(1.20);
}

.drop_hover_2 {
  transition: 0.3s ease-in;
}

.drop_hover_2:hover {
  transform: translateY(2px);
}

 .trail_extension{
  background-color: #F9DADE;
  color: #FF5247;
 }

 .common_input_styling{
  display: flex;
  align-items: center;
  font-size: 14px;
  height: 32px;
  padding: 0px 12px;
  border-radius: 4px;
  border: 1px solid var(--light-border-color, #E6E9EE);
  outline: none;
  box-shadow: none;
}

.common_input_styling:focus{
	border: 1px solid #0078ff;

}

 .cp{
  cursor: pointer;
 }
 .dropdown-menu.show{
  padding: 4px;
 }

 .dropdown-menu.show .dropdown-item{
  /* color: #676878; */
  font-size: 14px;
 }
 .standard_input{
	width: 100%;
	height: 32px;
	font-size: 14px;
  }

.free_view_dropdown1 {
  top: -28px !important;
}

.free_view_dropdown2 {
  top: -28px !important;
}

.free_view_dropdown3 {
  top: -28px !important;
}

.free_view_dropdown4 {
  top: -4px !important;
}

  .all_views_div_inner ::-webkit-scrollbar{
	height: 0px !important;
  }

  .all_views_div_outer ::-webkit-scrollbar{
	height: 0px !important;
  }

.item_invisible{
  visibility: hidden;
}

.visible_on_hover:hover .item_invisible{
  visibility: visible;
}

.bg_blue_hover:hover{
  background-color: var(--inf-primary-blue-hover-color, #e5f1ff) !important;
}

.infurnia-avatar {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  margin: 2px 0px;
  color: rgba(0, 120, 255, 1);
  background-color: rgba(0, 120, 255, 0.1);
  border: 1px solid white;
  justify-content: center;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500
}

.mes_panel {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	font-size: 12px;
	cursor: pointer;
	border-radius: 8px;
	--tw-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);
    --tw-shadow-colored: 0 2px 12px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.mes_panel:hover{
	--tw-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
    --tw-shadow-colored: 0 2px 12px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.inf_loading_background {
  background: url(/resources/icons/loading_animation.gif) center center no-repeat;
  background-color: white;
}