.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 10000;
    flex-direction: column;
  }
  
  .image-container {
    position: relative;
    width: 400px; /* Adjust to your desired image width */
    height: auto; /* Adjust to your desired image height */
    overflow: hidden;
  }
  
  /* img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  } */
  
  .mask {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.7;
    transform-origin: right;
  }
  
  .loading_text{
    color: #676878;
    font-size: 16px;
    font-style: italic;
  }

  .tips-container{
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px 12px;
    border-radius: 20px;
    font-style: italic;
    color: #676878;
    background-color: #F5F6F8;
  }