.inf_nav_sidebar_transparent.active{
    color: #3872C5;
    background: #E3EAF4;
    border-right: 2px solid #3872C5;
}

.inf_nav_sidebar_transparent{
    height: 40px;
    color: #676878;
    display: flex;
    align-items: center;
    padding: 0px 16px;
    cursor: pointer;
}

.inf_nav_sidebar_transparent:hover{
    background: #E3EAF4;
}