.infurnia-table {
    width: 100%;
    border-collapse: collapse;
}

.infurnia-table th,
.infurnia-table td {
    padding: 8px;
}

.infurnia-table-row.error_in_infurnia_table_row {
    background-color: #ffe6e6 !important;
}

.infurnia-table th {
    /* background-color: #f2f2f2; */
    font-weight: 500;
    text-align: left;
    color: #A7A8B2;
}

.infurnia-table-striped tbody tr:nth-of-type(odd) {
    background-color: #f9f9f9;
}

.infurnia-table-bordered {
    border: 1px solid #ccc;
}

.infurnia-table-dark {
    color: #fff;
    background-color: #343a40;
}

.infurnia-table-dark th,
.infurnia-table-dark td {
    border-color: #454d55;
}

.infurnia-table-hover tbody tr:hover {
    background-color: var(--inf-primary-blue-hover-color);
}

.table-responsive {
    overflow-x: auto;
}

.infurnia-table-bordered .infurnia-table-row:not(:last-child) {
    border-bottom: 1px solid var(--light-border-color);
}

.infurnia-table-row-separated .infurnia-table-row:not(:last-child) {
    border-bottom: 1px solid var(--light-border-color);
}

.infurnia-table-bordered .infurnia-table-cell:not(:last-child) {
    border-right: 1px solid var(--light-border-color);
}

.infurnia-table-bordered .infurnia-table-header:not(:last-child) {
    border-right: 1px solid var(--light-border-color);
}

.infurnia-table-column-separated .infurnia-table-cell:not(:last-child) {
    border-right: 1px solid var(--light-border-color);
}

.infurnia-table-column-separated .infurnia-table-header:not(:last-child) {
    border-right: 1px solid var(--light-border-color);
}

.infurnia-table-header-row {
    border-bottom: 1px solid var(--light-border-color);
}

.infurnia-table-container::-webkit-scrollbar{
    width: 8px;
    height: 8px;
}

.infurnia-table-container::-webkit-scrollbar-track{
    background-color: #E6E9EE;
    box-shadow: none;
}

/* .infurnia-table-container::-webkit-scrollbar-corner{
    background-color: #E6E9EE;
} */

.infurnia-table-container::-webkit-scrollbar-thumb{
    background-color: #A7A8B2;
    border-radius: 4px;
}

.infurnia-table-container::-webkit-scrollbar-thumb:hover{
    background-color: #676878;
}