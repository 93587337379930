.black_white_nav_item .nav-link {
    color: black;
}

.active_view{
    color: #323338 !important;
    background-color: white;
}
.active_view .x1{
    color: #323338 !important;
}
.active_view .x1 .x2{
    color: #323338 !important;
}

.inactive_view_container:hover{
    background-color: #E1E4EA !important;
}

.inactive_view{
    color: #323338 !important;
    /* flex-shrink: 1;
    min-width: 32px; */
}

.inactive_view_container{
    background-color: #EDEFF2 !important;
    /* min-width: 95px; */
    border-top: 1px solid #8E8F9E;
}


.close_icon_on_hover{
    display: none;
}

.inactive_view_container:hover .close_icon_on_hover{
    display: unset;
}

.solid_lock{
    color: #676878;
}

/* .solid_lock:hover{
    color: #4597F7;
    display: none !important;
    cursor: pointer;
} */

.solid_unlock{
    color: #676878;
    display: none !important;
}

.view_settings_icon:hover{
    color: #0078FF;
}

.view_settings_icon{
    color: #676878;
}

.close_view{
    color: #676878;
}

.close_view:hover{
    color: #FF5247;
}

/* .solid_unlock:hover{
    display: flex !important;
    color: #4597F7;
    cursor: pointer;
} */

.solid_lock_unlock:hover .solid_lock{
    display: none !important;
}

.solid_lock_unlock:hover .solid_unlock{
    display: flex !important;
    color: #4597F7;
    cursor: pointer;

}



/* .inactive_view_container:hover{
    flex-shrink: 0;
    min-width: 32px;
    background-color: #E1E4EA !important;
} */

.active_view_container{
    border-top: 2px solid #E6E9EE;
    border-bottom: 2px solid #8E8F9E
    /*  box-shadow: 0px 1px 2px 0px #0078FF80; */
}