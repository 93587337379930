.new_project_modal_div {
    display: flex;
    /* padding: 0px 32px; */
    margin-bottom: 16px;
}

.close{
    opacity: 1;
    /* color:#fff; */
}

.close:hover{
    opacity: 1;
    /* color:#fff; */
}

.help_menu{
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    padding-bottom: 32px;
}

@media only screen  and (max-width: 991px){
    .help_menu{
        width:100%;
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content: space-between;
        padding: 12px;
    }
}

#project_table {
    border-collapse: collapse;
}

#project_table tr {
    border-right: 1px solid #E6E9EE;
    border-left: 1px solid #E6E9EE;
    padding: 6px 12px;
}

#project_table td {
    /* border: 1px solid #E6E9EE; */
    padding: 6px 12px;
}

#project_table th {
    /* border: 1px solid #E6E9EE; */
    padding: 6px 12px;
    height: 100%;
    display: flex;
    align-items: center;
    color: rgba(167, 168, 178, 1) ;
    font-weight: 500;
}


#project_table tr:nth-child(odd){background-color: white;}
#project_table tr:nth-child(even){background-color: #F5F6F8;}
/* #project_table tr:hover {  background-color:rgb(214, 230, 240);} */
