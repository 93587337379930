.material_dropdown_menu {
    width: 295px;
    height: 300px;
    position: absolute !important;
    left: 124px !important;
    top: 110px !important;
}

.material_dropdown_menu_bottom {
    width: 295px;
    height: 300px;
    position: absolute !important;
    left: 124px !important;
}

.mcd_dd_pos {
    left: 210px !important;
     top: -40px !important;
}

.mcd_dd_pos_bottom {
    left: 210px !important;
    top: 200px !important;
}

.unset_boxshadow:focus {
    box-shadow: none !important;
}