.on_hover:hover{
    box-shadow: 0px 0px 7px #314456 inset;
}

.unit_disabled{
    opacity:0.3;
    cursor:not-allowed;
}

.tooltip_hover:hover {
    visibility: visible;
}
.tooltip3 {
    visibility: hidden;
    width: 100px;
    background-color: #cccccc;
    color: #000000;
    text-align: center;
    padding: 5px 0;
    font-size: small;
    opacity: 1;
    position: absolute;
    right: 100px;
    top: -20%;
    z-index: 1;
}
.hover_leftmenu_green_border{
    box-shadow: rgb(0 0 0 / 25%) 0 1px 0, inset rgb(255 255 255 / 16%) 0 1px 0;
    border-right: 3px solid rgba(230, 233, 238, 1) !important;
}