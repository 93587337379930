.infurnia-button {
  padding: 6px 12px;
  font-size: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  min-width: 72px;
  position: relative;
}

.infurnia-button:disabled{
  opacity: 0.5;
}

.infurnia-button:active {
  transform: scale(0.95);
}

.infurnia-button:hover {
  opacity: 0.9;
}

.infurnia-button:disabled:hover {
  opacity: 0.5;
}

.infurnia-button.outline {
  background-color: transparent;
  color: #000;
  border: 1px solid;
}

.infurnia-button.small {
  padding: 4px 8px;
  font-size: 12px;
  line-height: 1.25rem;
}

.infurnia-button.medium {
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.25rem;
}

.infurnia-button.large {
  padding: 8px 16px;
  font-size: 18px;
  line-height: 1.75rem;
}

.infurnia-button.primary {
  background-color: #007bff;
  color: #fff;
  border: 1px solid #0078ff;
}

.infurnia-button.secondary {
  background-color: #FFFFFF;
  color: #000000;
  border: 1px solid
}

.infurnia-button.success {
  background-color: #28a745;
  color: #fff;
}

.infurnia-button.warning {
  background-color: #f9dade;
  color: #ff5247;
}

.infurnia-button.ghost {
  padding: 0px;
  min-width: unset;
}

.infurnia-button.ghost.primary {
  background-color: transparent;
  color: #007bff;
  border: none;
}

.infurnia-button.ghost.secondary {
  background-color: transparent;
  color: #000000;
  border: none
}

.infurnia-button.ghost.success {
  background-color: transparent;
  color: #28a745;
  border: none;
}

.infurnia-button.ghost.warning {
  background-color: transparent;
  color: #ff5247;
  border: none
}

.infurnia-button.outline.primary {
  background-color: transparent;
  color: #007bff;
  border: 1px solid #007bff;
}

.infurnia-button.outline.secondary {
  background-color: transparent;
  color: #000000;
  border: 1px solid #000000
}

.infurnia-button.outline.success {
  background-color: transparent;
  color: #28a745;
  border: 1px solid #28a745;
}

.infurnia-button.outline.warning {
  background-color: transparent;
  color: #ff5247;
  border: 1px solid #f9dade
}