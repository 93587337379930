:root .form-control {
    font-size: 1rem;
}

.ant-tooltip-inner {
    max-height: 300px;
    overflow: auto;
}

.ant-dropdown-menu-sub {
    max-height: 300px;
    overflow: auto;
}

.ant-dropdown-menu-submenu-title{
    display: flex !important;
    align-items: center;
}

.ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background: transparent !important;
}

.ant-steps-item-active > .ant-steps-item-container > .ant-steps-item-icon {
    background: #0078FF1A !important;
    border: 1px solid #0078FF80 !important;
}

.ant-steps-item-active > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
    color: #0078FF !important;
    font-weight: 700;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-icon {
    background: #E6E9EE !important;
    border: 1px solid #C5C7CF !important;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
    color: #676878 !important;
    font-weight: 700;
}


.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-icon {
    background: none !important;
    border: 1px solid #29C940 !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
    color: #29C940 !important;
    font-weight: 700;
}


/* .ant-dropdown-menu-item-group{
    margin: -4px -4px 0 -4px;
    background: #778394;
    border-radius: 4px 4px 0 0;
} */
.panel-mcd-color-picker .chrome-picker{
    width: 100% !important;
    box-shadow: unset !important;
}
.react-confirm-alert-overlay{
    background-color: rgba(0, 0, 0, 0.5) !important;
}

span:has(.react-joyride__beacon){
    top: -5px !important;
    left: 2px !important;
}

.react-joyride__overlay{
    height: 100vh !important;
}

.connector_set_selection_card .ant-checkbox:not(.ant-checkbox-checked) .ant-checkbox-inner{
    border: 2px solid #C5C7CF;
}