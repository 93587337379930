
.flex-1-mw {
    flex: 1;
    min-width: 1px;
}

.flex-2-mw {
    flex: 2;
    min-width: 1px;
}

.flex-3-mw {
    flex: 3;
    min-width: 1px;
}

.flex-4-mw {
    flex: 4;
    min-width: 1px;
}

.flex-5-mw {
    flex: 5;
    min-width: 1px;
}

.flex-6-mw {
    flex: 6;
    min-width: 1px;
}

.flex-7-mw {
    flex: 7;
    min-width: 1px;
}

.flex-8-mw {
    flex: 8;
    min-width: 1px;
}

.flex-9-mw {
    flex: 9;
    min-width: 1px;
}

.flex-10-mw {
    flex: 10;
    min-width: 1px;
}

.flex-11-mw {
    flex: 11;
    min-width: 1px;
}

.flex-12-mw {
    flex: 12;
    min-width: 1px;
}

.flex-1-mh {
    flex: 1;
    min-height: 1px;
}

.flex-2-mh {
    flex: 2;
    min-height: 1px;
}

.flex-3-mh {
    flex: 3;
    min-height: 1px;
}

.flex-4-mh {
    flex: 4;
    min-height: 1px;
}

.flex-5-mh {
    flex: 5;
    min-height: 1px;
}

.flex-6-mh {
    flex: 6;
    min-height: 1px;
}

.flex-7-mh {
    flex: 7;
    min-height: 1px;
}

.flex-8-mh {
    flex: 8;
    min-height: 1px;
}

.flex-9-mh {
    flex: 9;
    min-height: 1px;
}

.flex-10-mh {
    flex: 10;
    min-height: 1px;
}

.flex-11-mh {
    flex: 11;
    min-height: 1px;
}

.flex-12-mh {
    flex: 12;
    min-height: 1px;
}

/* Border */
.inf-border-light{
    border: 1px solid var(--very-light-border-color);
}

.inf-border{
    border: 1px solid var(--light-border-color);
}

.inf-border-dark{
    border: 1px solid var(--dark-border-color);
}