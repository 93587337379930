.infurnia_logo {
    position: absolute;
    bottom: calc( var(--bottom_status_bar_offset_on_logo) + 4px );
    right:20px;
    padding: 0;
    margin: 0;
    font-size: 15px;
    font-weight: bold;
    color: #00000075;
    pointer-events: none;
}

.infurnia_logo span {
  margin: 0;
  padding-bottom: 0;
  vertical-align: -webkit-baseline-middle;
}

.diagonal_line {
  width: 2px; /* Adjust the width as needed */
  height: 25px;
  border-top: 2px solid #000; /* Adjust the color and thickness as needed */
  border-right: none;
  border-bottom: none;
  border-left: none;
  transform: rotate(45deg);
  transform-origin: 0 100%;
}

.green_hover{
    color: #ffffff;
}

.green_hover:hover{
    color: #1ABB9C;
    text-decoration: none;
}

.gray_hover:hover{
  background-color: #F5F6F8;
}

.design_border_radius{
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}x

.blue_hover{
    color: #ffffff;
}

.blue_hover:hover{
    color:rgba(0, 120, 255, 1);
    text-decoration: none;
}

.red_hover:hover{
  color: red;
  text-decoration: none;
}

.black_white_nav_item .nav-link {
    color: black;
}

.active_carosel_tab_css{
    background-color: white !important;
    color:black !important;
    border-radius: 5px 5px 0px 0px !important;
}

/* .carousel_sub_category_tab.active::before{
  content: '';
  position: absolute;
  bottom: -1px;
  height: 2px;
  width: 100%;
  background-color: #494A55;
} */

.carousel_sub_category_tab{
  background-color: inherit;
  color: white;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  font-size: 14px;
  padding: 4px 8px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;
  gap: 8px;
  position: relative;
}

.carousel_sub_category_tab:hover{
  background-color: #36373F80;
}

.carousel_sub_category_tab.active{
  background-color: #36373F;
  font-weight: bold;
  padding: 8px 8px 6px;
  /* border: 1px solid white;
  border-bottom: none; */
}

.infurnia_support_launcher {
    position: fixed;
    top: 100px;
    right:20px;
    padding: 0;
    margin: 0;
    font-size: 15px;
    font-weight: bold;
    color: #00000075;
    cursor: pointer;
}
.version_save_response {
    position: absolute;
    top: 150px;
    right:20px;
    padding: 0;
    margin: 0;
    font-size: 15px;
    font-weight: bold;
    color: #00000075;
    background-color: white;
    cursor: pointer;
}
.infurnia_support_launcher:hover {
    color: #009688;
}

.black_text {
    color: black;
}

.black_inf_text {
    color: #323338;
}

.elevation_default {
    border-radius: 8px;
    color: black !important;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 5px 10px 5px 10px !important;
}

.elevation_selected {
    border-radius: 8px;
    color: white !important;
    background-color: #0078ff;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 5px 10px 5px 10px !important; 
}

#elevation_cameras_dropdown {
    padding: 0px !important;
    padding-bottom: 0px !important;
    height: 100%;
    display: flex;
    align-items: center;
}

.triangle-topright {
    width: 0;
    height: 0;
    border-top: 24px solid white;
    border-left: 14px solid transparent;
    left: 8px;
    position: relative;
  }

  .triangle-topleft {
    width: 0;
    height: 0;
    border-top: 24px solid white;
    border-right: 14px solid transparent;
    position:relative;
    left: -8px;
  }

  .statusbar_container{
    position: absolute;
    z-index: 5;
    padding: 0px 16px;
    border-radius: 6px;
    box-shadow: 0px 2px 8px 0px #00000040;
    font-size: 12px;
    /* opacity: 0.5; */
    height: 32px;
    /* color: #000000; */
  }

  .canvas_icons_container{
    cursor: pointer;
    background-color: white;
    /* padding: 8px; */
    border-radius: 6px;
    font-size: 12px;
    font-weight: 700;
    color: #676878;
    height: 64px;
    width: 64px;
    box-shadow: 1px 1px 16px 2px #0000000F;
    border: 1px solid #E6E9EE
  }

  .command_line{
    position: absolute;
    top: 140px;
    right: 10px;
    background-color: #E6E9EE;
    border-radius: 4px;
    width: 250px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
  }

  .command_line_text{
    border-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    /* width:70%; */
    background-color: white;
    font-size: 14px;
    height: 28px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    border: 0;
  }

  .command_valid{
    background-color: white;
    /* width: 20px; */
    height: 28px;
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    padding-right: 10px;
  }

  .command_line_options{
    position: absolute;
    top: 180px;
    right: 10px;
    max-height: calc(100vh - 220px);
    overflow-y: auto;
    background-color: white;
  }

  .command_line_option{
    background-color: #F5F6F8;
    /* border-radius: 4px; */
    width: 250px;
    height: 36px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    padding: 8px;
    padding-left: 12px;
    font-size: 14px;
    cursor: pointer;
  }

  .command_line_option_icon{
    height: 24px;
  }

  .command_line_option:hover{
    background-color: #E6E9EE;
  }

  .command_line_option_text{
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
    flex: 1;
    min-width: 0px;
    text-align: left;
    display: flex;
  }

.bottom_status_bar {
  --bottom_status_bar_border_color: #A7A8B3 ;
  --bottom_status_bar_height: 32 ;
  font-family: D;
  /* position: absolute; */
  bottom: 0px;
  /* right: 200px; */
  /* background-color: white; */
}

  /* comments */

  .comment_logo{
    position: absolute;
    top: 60px;
    right: 8px;
    /* padding: 12px; */
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }

  .comment_logo.comment_mode{
    background-color: #D3EDFD;
  }

  .comment-pin{
    border-radius: 50% 50% 50% 0%;
    height: 32px;
    width: 32px;
    transform: translateY(-100%);
    background-color: #ffb323;
    color: black;
    border: 1.5px solid black
  }

  /* .comment-pin:hover{
    border-radius:  12px 12px 12px 0px;
    height: auto;
    width: 150px;
    padding: 8px;
  } */

  .comment-preview{
    display: none;
  }

  /* .comment-pin:hover .comment-preview{
    display: block;
  }

  .comment-pin:hover .comment-user{
    display: none;
  }
/* 
  .rbt-token.rbt-token-removeable::after{
    content: "AND";
  } */
/* 
  .rbt-input-wrapper::after{
    content: "AND";
    display: flex;
  } */

  .view_layer_menu .ant-dropdown-menu {
    background-color: #E6E9EE;
    padding: 0px;
    border-radius: 12px;
  }

  .view_layer_menu .ant-dropdown-menu > .ant-dropdown-menu-item-group > .ant-dropdown-menu-item-group-title {
    padding: 0px;
  }

  .view_layer_menu .ant-dropdown-menu > .ant-dropdown-menu-item-group {
    height: calc( 0.75 * var(--inf-canvas-height, 500px) );
    min-width: 240px;
    overflow: auto;
  }


  .dynamic_view_options{
    display: flex;
    align-items: center;
    padding-left: '10px';
    border-left: 1px solid #8E8F9E;
    /* border-top: 1px solid #8E8F9E; */
  }

  #all_views_div_inner > div{
    height: 100%;
  }

  .inactive_view_option:hover{
    background: #E6E9EE;
  }

  .active_view_option{
    background: #E1E9F5;
  }

  .active_view_option:hover{
    background: #C2D2EB;
  }

  .slider_camera_container{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .camera_rotation{
    position: absolute;
    top: 40px;
  }


  .slider_camera_position {
    -webkit-appearance: none;
    width: 100px;
    height: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    transform: rotateZ(270deg);
    /* flex: 1; */
  }
  
  .slider_camera_position:hover {
    opacity: 1;
  }
  
  .slider_camera_position::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 6px;
    height: 6px;
    background:  #0078ff;
    cursor: pointer;
    border-radius: 3px;
  }

  .camera_input_container{
    width: 52px;
    border-radius: 25px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: absolute;
    bottom: 50px;
    right:20px;
    background-color: white;
    border: 1px solid #C5C7CF;
  }

  .camera_image_container{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #CCF4E3;
    border-top: 1px solid #C5C7CF;
    cursor: pointer;
  }

  .camera_entry_image_container{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #CCF4E3;
    position: absolute;
    bottom: 50px;
    right:20px;
    border: 1px solid #C5C7CF;
    cursor: pointer;
  }

  .render-mini-view{
    position: absolute;
    bottom: 60px;
    right:90px;
    width: 300px;
    background-color: white;
    border-radius: 6px;
    border: 1px solid #C5C7CF;
    padding: 8px;
    /* padding: 0; */
    /* margin: 0; */
    /* font-size: 15px; */
    /* font-weight: bold; */
    /* color: #00000075; */
    /* pointer-events: none; */
  }

  .rotatable_slider_input:focus{
    border: 1px solid #0078ff !important;
  }

  .rotatable_slider_input{
    text-align: center;
  }

  .single_carousel_sku_parameter{
    width: 240px;
    background-color: white;
    flex-shrink: 0;
    border-right: 1px solid black;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    /* justify-content: space-between; */
  }

  .single_carousel_sku_parameter img{
    flex: 1;
    min-height: 0px;
    object-fit: contain;
  }

  .display_sku_carousel{
    overflow-x: auto;
    max-width: 100%;
    height: 100%;
  }

  .infurnia_carousel_body{
    background-color: #36373f;
  }

  .carousel_animation{
    transition: 0.5s ease-in-out;
  }

  /* .infurnia_carousel:hover .infurnia_carousel_body {
    background-color: rgba(0, 0, 0, 0.7);
  } */

  .infurnia_carousel_header{
    background-color: var(--inf-theme-gray-800);
    padding-top: 6px;
    padding-bottom: 2px;
  }

  /* .infurnia_carousel:hover .infurnia_carousel_header {
    background-color: rgba(0, 0, 0, 0.8);
  } */

  .infurnia_carousel_header.inactive_carousel_tab{
    background-color: rgba(55, 57, 58, 0.6);
  }

  /* .infurnia_carousel:hover .infurnia_carousel_header.inactive_carousel_tab {
    background-color: rgba(255, 255, 255, 0.8);
  } */


  .carousel_minimized .infurnia_carousel_header{
    background-color: rgba(0, 0, 0, 0.7);
  }

  .carousel_minimized:hover .infurnia_carousel_header {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .carousel_minimized .infurnia_carousel_header.inactive_carousel_tab{
    background-color: rgba(255, 255, 255, 0.6);
  }

  .carousel_minimized:hover .infurnia_carousel_header.inactive_carousel_tab {
    background-color: rgba(255, 255, 255, 0.7);
  }

.navigation_info_share_view{
  border-radius: 4px;
  background-color: #E6E9EE80;
  max-width: 270px;
}

#canvas_container_inner::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

#canvas_container_inner::-webkit-scrollbar-track {
  background-color: #E6E9EE;
  box-shadow: none;
}

/* #canvas_container_inner::-webkit-scrollbar-corner{
  background-color: #E6E9EE;
} */

#canvas_container_inner::-webkit-scrollbar-thumb {
  background-color: #A7A8B2;
  border-radius: 4px;
}

#canvas_container_inner::-webkit-scrollbar-thumb:hover {
  background-color: #676878;
}

.infurnia_ribbon_tab{
  background-color: transparent;
}

.infurnia_ribbon_tab:hover{
  background-color: #F5F6F880;
}

.infurnia_ribbon_tab .triangle-topleft{
  visibility: hidden;
}

.infurnia_ribbon_tab .triangle-topright{
  visibility: hidden;
}

.infurnia_ribbon_tab:hover .triangle-topleft{
  visibility: visible;
}

.infurnia_ribbon_tab:hover .triangle-topright{
  visibility: visible;
}

.bottom_status_bar_default_state{
  width: calc( 100% - 24px );
  background: var(--bottom_status_bar_border_color);
  position: absolute;
  left: 12px;
  bottom: 3px;
  height: 24px;
  font-size: 12px;
  padding: 0px 12px 0px 4px;
  clip-path: polygon(4% 0, 96% 0, 100% 100%, 0% 100%);
  display: flex;
  align-items: center;
}

#infurnia_help_center{
  background: url(/resources/icons/loading_animation.gif) center center no-repeat;
  background-color: white;
}

.table_style { 
	flex-direction: column; 
	width: 100% !important;
} 

.tr_style {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-shadow: inset 0 -1px 0 0 #F2F4F6;
}

.th_style {
	flex:1;
	height:40px;
	padding-left: 12px;
	font-size: 12px;
	font-weight: unset;
	/* border-right: 1px solid #f2f4f6; */
	color:#627386;
}

.td_style_no_scroll {
	flex:1;
	min-height: 40px;
	padding-left: 12px;
	font-size: 12px;
	color:#22272e;
	width:100%;
	word-break: break-all;
	border-right: 1px solid #f2f4f6;
	text-transform:capitalize;
}

.table_text_overflow{
	width:100% !important;
	overflow-x: auto !important;
}

.perspective_switcher_container{
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 2px 4px;
  border: 1px solid rgba(228, 228, 227, 1);
  border-radius: 6px;
  height: 36px;
  gap: 4px;
}

.perspective_item{
  padding: 4px 8px;
  border-radius: 6px;
  font-weight: 500;
  height: 100%;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.perspective_item:hover{
  background-color: var(--secondary-background-design-navbar, #a7a8b340);
}

.perspective_item.perspective_item_active{
  background-color: #0078ff;
  color: white;
}

.perspective_item.perspective_item_semi_active{
  /* background-color: rgb(0, 120, 255, 0.1); */
  background-color: var(--secondary-background-design-navbar, #a7a8b340);
  /* color: white; */
}