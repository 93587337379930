.search_component_container_ideal {
    display:flex; 
    align-items:center; 
    border:1px solid #C5C7CF;
    border-radius: 2px;
    margin: 0px 16px 0px 16px;
    background-color: white;
}

.search_component_inner_container {
    width:200px; 
    margin-right:16px; 
    font-size:12px;
    padding:6px 12px 6px 12px;
    border:0px;
    height: 100%;
}

.search_component_container_ideal:focus-within {
    box-shadow: 0 0 0 0.1rem rgb(0 123 255 / 25%);
    border: 1px solid #0078FF;
}

.search_component_inner_container:focus-visible{
    outline: none;
}