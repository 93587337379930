#infurnia-design-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--infurnia-navbar-height, 52px);
  padding: 8px 16px;
  background-color: white;
  color: black;
  border-bottom: 1px solid rgb(230, 233, 238);
  --secondary-background-design-navbar: #a7a8b360;
}

.mes_design_edit_mode #infurnia-design-navbar{
    background-color: #303030;
    color: white;
}

.perspective_switcher_container{
    /* background-color: rgb(167 168 179 / 25%); */
}

#infurnia-design-bread-crumb-bar{
    display: flex;
    align-items: center;
    /* height: 100%; */
    background-color: #F3F4F6;
    border-top-left-radius: 4px;
    border-bottom-left-radius:4px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
}

 .mes_design_edit_mode #infurnia-design-bread-crumb-bar{
    background-color: #57585c;
}

#infurnia-design-bread-crumb-bar>*{
    background: linear-gradient(to right, #F3F4F6, #EBEDF1) !important;
}

 .mes_design_edit_mode #infurnia-design-bread-crumb-bar>*{
    background: linear-gradient(270deg, #404145 -56.24%, #616368 170.83%) !important;
}


.infurnia-design-navbar-logo{
    max-width: 120px;
    max-height: 100%;
    font-size: 32px;
    object-fit: contain;
}

#mes_work_order_info{
    display: flex;
    border-radius: 4px;
    overflow: hidden;
    max-width: 120px;
}

#infurnia-design-bread-crumb-bar a:hover{
    color: #0078ff;
}
#infurnia-design-bread-crumb-bar a{
    color: inherit;
}

.navbar_item_hover:hover{
    background-color: var(--secondary-background-design-navbar, #a7a8b340);
}

.design-view-navbar-icons{
    cursor: pointer;
    font-size: 24px;
    padding: 4px;
}

.design-view-navbar-icons:hover{
    background-color: var(--secondary-background-design-navbar, #a7a8b340);
}

.design-bread-crumb-nav-item{
    /* background: linear-gradient(to right, transparent, #FFFFFF30); */
    padding: 8px;
    height: 30px;
    border-top-right-radius: 999px;
    border-bottom-right-radius: 999px;
    font-size: 12px;
    display: flex;
    align-items: center;
}