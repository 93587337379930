@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height:3px
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #C5C7CF; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #A7A8B3; 
}

.hide {
  display: none;
}

.hide:focus {
  display: block;
}

.hide:focus-within {
  display: block;
}
    
.myDIV:hover .x1 .hide {
  display: block;
}

.myDIV:hover .x1 .x2 .hide {
  display: block;
}
.myDIV:hover{
  background-color: pink;
}

.show_on_hover:hover .hide {
  display: block;
}

.show_on_hover:hover .invisible_icon {
  visibility: visible;
}

.show_on_hover:hover .hidden_dropdown {
  display: flex;
}

.hidden_dropdown {
  display: none;
}

.child{
  display:none;
}
.parent:hover  .child{
  display: block;
}
.table_custom_strip:nth-of-type(odd) {
    background-color: #f9f9f9;
}

/* icons hover green */
.hover_green:hover{
  color:#1ABB9C !important;
}

.hover_blue:hover{
  color:rgba(0, 120, 255, 1) !important;
}

.blue_button{
  height:32px;
  min-width:88px;
  width:auto;
  background-image: linear-gradient(0deg, #007EF4 0%, #0788DE 100%);
  box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.20), inset 0 0 0 1px rgba(0,34,66,0.20);
  border-radius: 3px;
  font-size:12px;
  border: 0;
  color:white;

}

.red_button{
  margin-right: 12px;
  border: 0;
  height:32px;
  width:88px;
  font-size:12px;
  background: #FFFFFF;
  background-image: linear-gradient(0deg, red 0%, red 100%);
  box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.09), inset 0 0 0 1px rgba(67,90,111,0.14);
  border-radius: 3px;
  color:white;
}

.white_button_auto{
  height:32px;
  min-width:88px;
  width:auto;
  background-image: linear-gradient(0deg, #F4F5F7 0%, #FFFFFF 100%);
  box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.09), inset 0 0 0 1px rgba(67,90,111,0.14);
  border-radius: 3px;
  font-size:12px;
  border: 0;
  color:black !important;
}

.white_button{
  margin-right: 12px;
  border: 0;
  height:32px;
  width:88px;
  font-size:12px;
  background: #FFFFFF;
  background-image: linear-gradient(0deg, #F4F5F7 0%, #FFFFFF 100%);
  box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.09), inset 0 0 0 1px rgba(67,90,111,0.14);
  border-radius: 3px;
  color:black !important;
}

/* for material and composite dropdown */
.class_position_unset{
  position: unset;
}

.form-control:focus{
  outline: 0 !important;
  /* border:0 !important; */
  box-shadow: none !important;
}
#input:focus-visible {    
  outline: 0 !important;
}

.tab_pills.active {
  background-color:#E6E9EE !important; 
  color:#676878 !important;
}
.tab_pills{
  color:black !important;
}
.tab_pills:hover{
  color:#676878 !important;
}

/* for Delete notification */

.react-confirm-alert-overlay{
  z-index: 9999 !important;
}


.loader {
  --cube-size: 10px;
  
  position: relative;
  width: calc(var(--cube-size) * 2);
  height: calc(var(--cube-size) * 2);
}

.loader .cube {
  width: var(--cube-size);
  height: var(--cube-size);
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  animation: loader-cube-1 2.4s .2s cubic-bezier(.72,.01,.01,1) infinite;
}

.loader .cube:nth-of-type(2) {
  background: white;
  right: 0;
  left: auto;
  animation-name: loader-cube-2;
  animation-delay: .4s;
}

.loader .cube:nth-of-type(3) {
  background: white;
  bottom: 0;
  top: auto;
  animation-name: loader-cube-3;
  animation-delay: 0s;
}

@keyframes loader-cube-1 {
  from, to {
    transform: translate(0%);
  }
  25% {
    transform: translateY(100%);
  }
  50% {
    transform: translate(100%, 100%);
  }
  75% {
    transform: translate(100%);
  }
}

@keyframes loader-cube-2 {
  from, to {
    transform: translate(0);
  }
  25% {
    transform: translate(-100%);
  }
  50% {
    transform: translate(-100%, 100%);
  }
  75% {
    transform: translateY(100%);
  }
}

@keyframes loader-cube-3 {
  from, to {
    transform: translate(0);
  }
  25% {
    transform: translate(100%);
  }
  50% {
    transform: translate(100%,-100%);
  }
  75% {
    transform: translateY(-100%);
  }
}

.gooey{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 142px;
  height: 40px;
  margin: -20px 0 0 -71px;
  background: white;
  filter: contrast(20);
}

.gooey .dot{
  position: absolute;
  width: 16px;
  height: 16px;
  top: 12px;
  left: 15px;
  filter: blur(4px);
  background: #000;
  border-radius: 50%;
  transform: translateX(0);
  animation: dot 2.8s infinite;
}
.gooey .dots{
  transform: translateX(0);
  margin-top: 12px;
  margin-left: 31px;
  animation: dots 2.8s infinite;
}

.gooey .dots span{
  display: block;
  float: left;
  width: 16px;
  height: 16px;
  margin-left: 16px;
  filter: blur(4px);
  background: #000;
  border-radius: 50%;
}

@keyframes dot{
  50%{
    transform: translateX(96px)
  }
}
@keyframes dots{
  50%{
    transform: translateX(-31px)
  }
}

.loading_animation {
  animation: rotation 2s infinite linear;
  height: 20px;
}

@keyframes rotation {
  from{
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.DMSans{
  font-family: 'DM Sans', sans-serif;
}
