.disabled_fa_icon {
    cursor: not-allowed  !important;
    opacity: 0.5 !important;
}

.vl {
    /* border-left: 6px solid green; */
    border-left: 2px solid #E6E9EE;;
    height: 36px;
    position: absolute;
    /* left: 50%; */
    /* margin-left: -3px; */
    top: 6px;
    left: 50px
}

/* .active_expand_160:active{
    width: 160px;
} */

.vl_end {
    /* border-left: 6px solid green; */
    border-left: 2px solid rgba(0, 0, 0, 0.15);
    height: 36px;
    /* position: absolute;
    /* left: 50%; */
    /* margin-left: -3px;
    top: 6px;
    left: 50px */
    margin-right: 16px;
}

.big_modal {
    width: 80vw;
}

#template_search:focus {
    box-shadow: none;
    /* border-radius: 2px; */
}

.edit_project_modal_div {
    display: flex;
    padding: 0px 32px;
    margin-bottom: 15px;
}

.search_container_ideal {
    display:flex; 
    align-items:center; 
    border:1px solid #C5C7CF;
    border-radius: 2px;
    background-color: white;
}

.search_container_ideal:focus-within {
    box-shadow: 0 0 0 0.1rem rgb(0 123 255 / 25%);
    border: 1px solid #0078FF;
}

.search_container_ideal:hover {
    opacity: 0.9;
}

#tooltip
{
  position: absolute;
  left: 0px;
  top: 0px;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 13px;
  display: block;
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: opacity 1s ease-in-out;
}

.show_on_hover:hover #tooltip
{
  margin-top: 50px;
  display: inline-block;
  opacity: 1;
  height: auto;
  transition-delay: 5s;
  background: transparent;
  z-index: 1;
  padding: 6px 12px 6px 12px;
  margin-left: 25px;
  border-radius: 6px;
  font-size: 14px;
  min-width: 330px;
  transition: opacity .5s ease-in-out;

  /* border: 1px solid white;
  border-radius: 2px; */
  /* border: 1px solid rgba(184, 191, 202, 1); */
}

#tooltip.bottom .tooltip-arrow
{
  top: 1px;
  left: 15%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: black;
}


/* .tooltip-arrow
{
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
} */

#tooltip .tooltip-arrow
{
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-right-color: transparent;
  border-style: solid;
}

#tooltip .tooltip-label
{
  max-width: 500px;
  height: 40px;
  min-width:150px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}
