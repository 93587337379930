/* ImageCarousel css */

.infurnia-image-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.infurnia-image-carousel-card {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    display: flex;
}

.infurnia-image-carousel-card>img{
    z-index: 1;
}

.infurnia-image-carousel-card:hover>img{
    transform: scale(0.85);
}

.fixed-image-carousel-card>img{
    transform: scale(0.95, 1);
}

.fixed-image-carousel-card:hover>img{
    transform: scale(0.95, 1);
}

.infurnia-carousel-navigation-buttons{
    background-color: rgb(50, 51, 56, 0.7);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 0;
    cursor: default;
}
